const initialState = {
  listLoading: false,
};
const LoadingStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LIST_LOADING_TRUE":
      return { ...state, listLoading: true };
    case "LIST_LOADING_FALSE":
      return { ...state, listLoading: false };
    default:
      return state;
  }
};

export default LoadingStateReducer;
