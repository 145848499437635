import React from "react";
import styled from "styled-components";
import SummaryCard from "./SummaryCard";
import Icons from "../../../Global/Icons";
import { ISummary } from "../utils";

const Summary = ({data}:{data:ISummary | undefined}) => {
  const summaryData = [
    {
      icon: Icons.TimeIcon,
      label: (
        <p>
          Total <br /> Watch Time <span>{`(in Mins)`}</span>
        </p>
      ),
      value: data?.totalWatchTime,
    },
    {
      icon: Icons.EyeIcon,
      label: (
        <p>
          Total Video <br /> Views
        </p>
      ),
      value: data?.totalVideoViews,
    },
    {
      icon: Icons.UserIcon,
      label: (
        <p>
          Unique <br /> Users
        </p>
      ),
      value: data?.totalUniqueUsers,
    },
    {
      icon: Icons.OrganisationIcon,
      label: (
        <p>
          Total <br />
          Organsations
        </p>
      ),
      value: data?.totalOrgansations,
    },
    {
      icon: Icons.BlueCourseIcon,
      label: (
        <p>
          Total <br />
          Courses
        </p>
      ),
      value: data?.totalCourses,
    },
    {
      icon: Icons.BlueVideoIcon,
      label: (
        <p>
          Total No. of. <br /> Live Videos
        </p>
      ),
      value: data?.totalLiveVideos,
    },
  ];

  return (
    <SummaryWrapper>
      {summaryData?.map((summary) => (
        <SummaryCard summary={summary} />
      ))}
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

export default Summary;
