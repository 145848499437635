import { gql } from "@apollo/client";

export const EXPERT_Topics_UPDATE = gql`
  query ($name: String!) @cached {
    function: neopayment_topic_master(
      where: { name: { _ilike: $name } }
      order_by: { name: asc_nulls_last }
    ) {
      topic_id
      name
      is_active
      __typename
    }
  }
`;

export const EXPERT_AWARDS_UPDATE = gql`
  query ($name: String!) @cached {
    function: neopayment_awards_master(
      where: { award_name: { _ilike: $name }, status: { _eq: "ACTIVE" } }
      order_by: { award_name: asc_nulls_last }
    ) {
      id
      award_name
    }
  }
`;

export const EXPERT_COMPANIES = gql`
  query ($name: String!) @cached {
    function: neopayment_company_master(
      where: { name: { _ilike: $name } }
      order_by: { name: asc_nulls_last }
    ) {
      id
      name
      is_active
      __typename
    }
  }
`;
export const EXPERT_SKILLS = gql`
  query ($name: String!) @cached {
    function: neopayment_unit_competency_skills_master(
      where: { skill_name: { _ilike: $name }, is_active: { _eq: true } }
      order_by: { skill_name: asc_nulls_last }
    ) {
      id
      skill_name
    }
  }
`;
export const EXPERT_ALUMS = gql`
  query ($name: String!) @cached {
    function: neopayment_pedigree_master(
      where: { pedigree: { _ilike: $name } }
      order_by: { pedigree: asc_nulls_last }
    ) {
      id
      pedigree
      is_active
      __typename
    }
  }
`;
export const EXPERT_ALUM_UPDATE = gql`
  mutation insert_multiple_author_educations(
    $objects: [neopayment_author_education_insert_input!]!
  ) {
    insert_neopayment_author_education(objects: $objects) {
      affected_rows
      __typename
      returning {
        author_id
        pedigree_id
        pedigree_others
        from_date
        to_date
        description
        specialisation
        status
      }
    }
  }
`;

export const EXPERT_ALUM_DELETE = gql`
  mutation update_single_neopayment_author_education($name: String!) {
    delete_neopayment_author_education(
      where: { pedigree_others: { _eq: $name } }
    ) {
      affected_rows
    }
  }
`;

export const EXPERT_COMPANIES_UPDATE = gql`
  mutation insert_multiple_author_experiences(
    $objects: [neopayment_author_experience_insert_input!]!
  ) {
    insert_neopayment_author_experience(
      objects: $objects
      on_conflict: {
        constraint: idx_author_experience
        update_columns: [status]
      }
    ) {
      affected_rows
      __typename
      returning {
        id
        author_id
        company_id
        company_others
        from_date
        to_date
        designation
        description
        is_current_company
        status
      }
    }
  }
`;

export const EXPERT_COMPANY_DELETE = gql`
  mutation update_single_neopayment_author_experience($name: String!) {
    delete_neopayment_author_experience(
      where: { company_others: { _eq: $name } }
    ) {
      affected_rows
    }
  }
`;

export const EXPERT_CERTIFICATIONS_ADD = gql`
  mutation insert_multiple_author_certifications(
    $objects: [neopayment_author_certifications_insert_input!]!
  ) {
    insert_neopayment_author_certifications(
      objects: $objects
      on_conflict: {
        constraint: pk_author_certifications
        update_columns: [
          certificate_name
          institution_name
          completion_date
          certificate_file_url
          is_ongoing
          status
        ]
      }
    ) {
      affected_rows
      __typename
      returning {
        author_id
        certificate_name
        institution_name
        completion_date
        certificate_file_url
        is_ongoing
        status
        __typename
      }
    }
  }
`;

export const EXPERT_WORK_EXPERIENCE = gql`
mutation update_author($totalExperience: String!, $experienceMetadata: jsonb!, $authorId: Int!) {
  update_neopayment_author(where: {author_id: {_eq: $authorId}}, _set: {total_working_experience: $totalExperience, experience_metadata: $experienceMetadata}) {
    returning {
      author_id
      total_working_experience
      experience_metadata
    }
  }
}
`;
