import styled, { css } from "styled-components";

const hover = (colorType, color) => {
  switch (colorType) {
    case "primary":
      return color.PRIMARY_100;
    case "secondary":
      return color.SECONDARY_100;
    case "base":
      return color.BASE_100;
    case "light":
      return color.LIGHT_100;
    case "success":
      return color.SUCCESS_SECONDARY_100;
    case "warning":
      return color.WARNING_PRIMARY_100;
    case "danger":
      return color.DANGER_PRIMARY_100;
    case "link":
      return "transparent";
    default:
      return color.BASE_100;
  }
};

const focused = (colorType, color) => {
  switch (colorType) {
    case "primary":
      return color.PRIMARY_500;
    case "secondary":
      return color.SECONDARY_500;
    case "base":
      return color.BASE_500;
    case "light":
      return color.LIGHT_500;
    case "success":
      return color.SUCCESS_SECONDARY;
    case "warning":
      return color.WARNING_PRIMARY;
    case "danger":
      return color.DANGER_PRIMARY;
    case "white":
      return color.WHITE;
    case "link":
      return "transparent";
    default:
      return color.BASE_500;
  }
};

export const ButtonStyle = styled.button`
  height: ${(props) => (props.height ? props.height : "auto")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s linear;
  white-space: nowrap;
  width: ${(props) => (props.width ? props.width : "max-content")};
  ${(props) =>
    props.outline
      ? css`
          border: 1px solid ${focused(props.bgColorType, props.theme.color)};
          background: transparent;
          color: ${focused(props.bgColorType, props.theme.color)};
          &:hover {
            background: ${focused(props.bgColorType, props.theme.color)};
            color: ${props.theme.color.WHITE};
          }
        `
      : css`
          border: 1px solid ${focused(props.bgColorType, props.theme.color)};
          background: ${focused(props.bgColorType, props.theme.color)};
          color: ${props.bgColorType === "link"
            ? props.theme.color.PRIMARY_400
            : props.theme.color.WHITE};
          &:hover {
            background: ${hover(props.bgColorType, props.theme.color)};
            border: 1px solid ${hover(props.bgColorType, props.theme.color)};
          }
        `}
  ${(props) => {
    switch (props?.size) {
      case "small":
        return css`
          padding: 4px 12px;
          font-size: 12px;
        `;
      case "default":
        return css`
          padding: 6px 16px;
          font-size: 16px;
        `;
      case "medium":
        return css`
          padding: 7px 20px;
          font-size: 18px;
        `;
      case "large":
        return css`
          padding: 8px 24px;
          font-size: 20px;
        `;
      default:
        return css`
          padding: 6px 16px;
          font-size: 16px;
        `;
    }
  }}

  ${(props) => {
    switch (props?.shape) {
      case "default":
        return css`
          border-radius: 2px;
        `;
      case "rounded-sm":
        return css`
          border-radius: 4px;
        `;
      case "rounded-md":
        return css`
          border-radius: 8px;
        `;
      case "rounded-lg":
        return css`
          border-radius: 12px;
        `;
      case "pill":
        return css`
          border-radius: 24px;
        `;
      default:
        return css`
          border-radius: 2px;
        `;
    }
  }}

  ${(props) =>
    props.disabled
      ? css`
          cursor: not-allowed !important;
          background: ${props.theme.color.GRAY_DISABLED};
          border: 1px solid ${props.theme.color.GRAY_DISABLED};
          color: ${props.theme.color.WHITE};
          &:hover {
            background: ${props.theme.color.GRAY_DISABLED};
            border: 1px solid ${props.theme.color.GRAY_DISABLED};
            color: ${props.theme.color.WHITE};
          }
        `
      : css`
          cursor: pointer;
        `}

        ${(props) =>
    props.textColor
      ? css`
          color: ${props.textColor};
          &:hover {
            color: ${props.textColor};
          }
        `
      : ""}

        ${(props) =>
    props.bgColor
      ? css`
          background: ${props.bgColor};
          &:hover {
            background: ${`${props.bgColor}aa`};
            box-shadow: 4px 4px 20px 0px ${`${props.bgColor}30`};
            border-color: ${`${props.bgColor}30`};
          }
        `
      : ""}
`;
