import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const axiosConfig: AxiosRequestConfig = {
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
};

const commonInterceptor = (instance: AxiosInstance) => {
    instance.interceptors.request.use((config) => {
     const token = localStorage.getItem("token");
        config.headers.Authorization = `${token}`;
      return config;
    });

};

const api: AxiosInstance = axios.create(axiosConfig);

commonInterceptor(api)
export default api;