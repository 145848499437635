import React, { useEffect, useState } from "react";
import { SideBarWrapper } from "./style";
import { Link, useLocation } from "react-router-dom";
import { Events } from "../../../../assets/constants/Events";
import Emitter from '../../../../lib/utils/emitter';
import RouteEnum from "../../../../assets/constants/RouteEnum";
import { home } from '../../../../assets/icons/home';
import { analyticsBar } from '../../../../assets/icons/analyticsBar';
import { graduationHat } from '../../../../assets/icons/graduationHat';
import { course } from '../../../../assets/icons/course';

import { Button } from "antd";
import AnalyticsIcon from "../../../../assets/icons/AnalyticsIcon";
import RevenueIcon from "../../../../assets/icons/RevenueIcon";
import UploadIcon from "../../../../assets/icons/UploadIcon";

const Sidebar = () => {
    const [expanded, setExpanded] = useState(false);

    let location = useLocation();
    const path = location?.pathname;

    const handleExpansion = ({ value }) => {
        setExpanded(value);
    }

    useEffect(() => {
        Emitter.on(Events.EXPAND_SIDEBAR, handleExpansion);
        () => {
            Emitter.off(Events.EXPAND_SIDEBAR, handleExpansion);
        }
    }, []);

    const sidebarWidth = expanded ? "250px" : "56px";

    const expand = () => {
        Emitter.emit(Events.EXPAND_SIDEBAR, { value: true });
        setExpanded(true);
    }

    const collapse = () => {
        Emitter.emit(Events.EXPAND_SIDEBAR, { value: false });
        setExpanded(false);
    }

    const pathname = location?.pathname;
    return (
        <SideBarWrapper
            width={sidebarWidth}
            onMouseEnter={expand}
            onMouseLeave={collapse}
        >
            <div>
                {path.startsWith('/kp') &&
                <><div className="tab">
                        <Link key={'home'} to={RouteEnum.HOME} onClick={collapse}>
                            <label className={`tab-label ${pathname === RouteEnum.HOME || pathname === RouteEnum.EDIT_PROFILE ? 'active' : ''}`}>
                                <span className="tab-icon">{home()}</span>
                                {expanded && "Home"}
                            </label>
                        </Link>
                    </div><div className={`tab ${expanded ? 'accordian' : ''} ${pathname === RouteEnum.CREATE_AND_LAUNCH || pathname === RouteEnum.MANAGE_OPERATIONS || pathname === RouteEnum.PAYMENT_AND_INVOICE ? 'active' : ''}`}>
                            <input type="checkbox" id="chck1" onClick={expand} />
                            <label className={`tab-label ${expanded ? 'accordian' : ''} ${pathname === RouteEnum.CREATE_AND_LAUNCH || pathname === RouteEnum.MANAGE_OPERATIONS || pathname === RouteEnum.PAYMENT_AND_INVOICE ? 'active' : ''}`} for="chck1">
                                <span className="tab-icon">{analyticsBar()}</span>
                                {expanded && "K-Commerce Suite"}
                            </label>
                            {expanded && <div
                                className="tab-content"
                            >
                                <Link
                                    onClick={collapse}
                                    key={'create-and-launch'} to={RouteEnum.CREATE_AND_LAUNCH} className="link-btn sublink">
                                    <span className="tab-icon" />
                                    {expanded && <p className="label">
                                        Create & Launch
                                    </p>}
                                </Link>
                                {/* <Link
        onClick={collapse}
        key={'promotions'} to={RouteEnum.CREATE_PROGRAM} className="link-btn sublink">
        <span className="tab-icon" />
        {
            expanded && <p className="label">
                Promotions
            </p>
        }
    </Link> */}
                                <Link
                                    onClick={collapse}
                                    key={'manage-operations'} to={RouteEnum.MANAGE_OPERATIONS} className="link-btn sublink">
                                    <span className="tab-icon" />
                                    {expanded && <p className="label">
                                        Manage Operations
                                    </p>}
                                </Link>
                                {/* <Link
            onClick={collapse}
            key={'payment-and-invoice'} to={RouteEnum.PAYMENT_AND_INVOICE} className="link-btn sublink">
            <span className="tab-icon" />
            {
                expanded && <p className="label">
                    Payment & Invoice
                </p>
            }
        </Link> */}
                            </div>}
                        </div><div className={`tab ${pathname === RouteEnum.YOUR_TRAILS || pathname === RouteEnum.PAY_IT_FORWARD || pathname === RouteEnum.TRENDING_ON_TRAILS ? 'active' : ''}`}>
                            <input type="checkbox" id="chck2" onClick={expand} />
                            <label className={`tab-label ${expanded ? 'accordian' : ''} ${pathname === RouteEnum.YOUR_TRAILS || pathname === RouteEnum.PAY_IT_FORWARD || pathname === RouteEnum.TRENDING_ON_TRAILS ? 'active' : ''}`} for="chck2">
                                <span className="tab-icon">{graduationHat()}</span>
                                {expanded && "Thought Academy Suite"}
                            </label>
                            {expanded && <div
                                className="tab-content"
                            >
                                <Link
                                    onClick={collapse}
                                    key={'your-trails'} to={RouteEnum.YOUR_TRAILS} className="link-btn sublink">
                                    <span className="tab-icon" />
                                    {expanded && <p className="label">
                                        Your Trails
                                    </p>}
                                </Link>
                                <Link
                                    onClick={collapse}
                                    key={'pay-it-forward'} to={RouteEnum.PAY_IT_FORWARD} className="link-btn sublink">
                                    <span className="tab-icon" />
                                    {expanded && <p className="label">
                                        Pay it Forward
                                    </p>}
                                </Link>
                                <Link
                                    onClick={collapse}
                                    key={'trending-on-trails'} to={RouteEnum.TRENDING_ON_TRAILS} className="link-btn sublink">
                                    <span className="tab-icon" />
                                    {expanded && <p className="label">
                                        Trending on Trails
                                    </p>}
                                </Link>
                            </div>}
                        </div></>
}
                
               {(!path.startsWith('/kp') ) &&
                <div>
                    <div className="tab">
                <Link key={'course'} to={RouteEnum.COURSE} onClick={collapse}>
                        <label className={`tab-label ${pathname.includes(RouteEnum.COURSE)  ? 'active':''}`}>
                            <span className="tab-icon"> <UploadIcon/></span>
                            {expanded && "Manage Courses"}
                        </label>
                    </Link>
                    </div>
                    <div className="tab">
                    <Link key={'analytics'} to={RouteEnum.ANALYTICS} onClick={collapse}>
                        <label className={`tab-label ${pathname === RouteEnum.ANALYTICS ? 'active': ''}`}>
                            <span className="tab-icon"><AnalyticsIcon/></span>
                            {expanded && "Content Analytics"}
                        </label>
                    </Link>
                    </div>
                    <div className="tab">
                    <Link key={'revenue'} to={RouteEnum.REVENUE} onClick={collapse}>
                        <label className={`tab-label ${pathname === RouteEnum.REVENUE ? 'active': ''}`}>
                            <span className="tab-icon"><RevenueIcon/></span>
                            {expanded && "Revenue Dashboard"}
                        </label>
                    </Link>
                    </div>
                </div>
                }
                </div>
         
            {/* In case we need to expand and close  */}
            {/* <Button onClick={expanded ? collapse : expand} style={{
                marginBottom: "32px"
            }}>
                {expanded ? "Collapse <" : ">"}
            </Button> */}
        </SideBarWrapper>
    );
};

export default Sidebar;
