import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import PropTypes from "prop-types";
import { ButtonStyle } from "./style";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
    }}
    spin
  />
);

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  width,
  outline,
  shape,
  size,
  label,
  bgColorType,
  bgColor,
  textColor,
  disabled,
  loading,
  onClick,
  height,
  iconLast,
  iconFirst,
}) => {
  const button = (
    <ButtonStyle
      height={height}
      bgColorType={bgColorType}
      size={size}
      width={width}
      shape={shape}
      outline={outline}
      disabled={disabled}
      bgColor={bgColor}
      textColor={textColor}
      onClick={() => {
        if (!disabled && typeof onClick === "function") return onClick();
      }}
    >
      <img src={iconFirst} /> {label} <img src={iconLast} />
    </ButtonStyle>
  );

  if (loading) {
    return (
      <div style={{ width: "max-content" }}>
        <Spin spinning={loading} indicator={antIcon}>
          {button}
        </Spin>
      </div>
    );
  }

  return <>{button}</>;
};

Button.propTypes = {
  outline: PropTypes.bool,
  shape: PropTypes.oneOf([
    "default",
    "rounded-sm",
    "rounded-md",
    "rounded-lg",
    "pill",
  ]),
  size: PropTypes.oneOf(["small", "default", "medium", "large"]),
  label: PropTypes.string,
  bgColorType: PropTypes.oneOf([
    "primary",
    "secondary",
    "base",
    "light",
    "success",
    "warning",
    "danger",
    "link",
  ]),
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
};

Button.defaultProps = {
  outline: false,
  shape: "default", // default, rounded-sm, rounded-md, rounded-lg, pill
  size: "default",
  label: "Button CTA",
  bgColorType: "base",
  onClick: undefined,
  loading: false,
  disabled: false,
  width: "",
  bgColor: undefined,
  textColor: undefined,
};

export default Button;
