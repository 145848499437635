import React, { useEffect, useState } from "react";
import { ThemeFontSize, ThemeSpace } from "../../../assets/theme";
import AwUserIcon from "../Atom/AwUserIcon";
import styled from "styled-components";
import { useSelector } from "react-redux";

const UserProfileMenuCard = styled.div`
  display: flex;
  padding: ${ThemeSpace()[4]};

  .profile-pic {
    width: auto;
    height: auto;
    margin-right: ${ThemeSpace()[8]};
    margin-top: ${ThemeSpace()[4]};
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .name {
    font-weight: 700;
    font-size: ${ThemeFontSize()[18]};
    line-height: 160%;
    margin-bottom: ${ThemeSpace()[4]};
  }

  .email {
    font-size: ${ThemeFontSize()[14]};
  }
`;
const AwProfileMenuCard = ({}) => {
  const data = useSelector((state) => state?.homePageData);
  const [name, setName] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    setName(data?.list?.displayName);
    setImage(data?.list?.photo?.imageurls?.url);
  }, [data]);
  const profilePic = <AwUserIcon type="image" imgSrc={image} />;

  return (
    <UserProfileMenuCard>
      <div className="profile-pic">{profilePic}</div>
      <div className="text-container">
        <div className="name">{name}</div>
        {/* <div className="email">{email}</div> */}
      </div>
    </UserProfileMenuCard>
  );
};

export default AwProfileMenuCard;
