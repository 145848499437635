import React, { useState, useEffect } from "react";
import { ThemeColor, ThemeSpace } from "../../../assets/theme";
import styled from "styled-components";
import {
  BarChartOutlined,
  BorderInnerOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const ProfileMenuList = styled.div`
  .containerMenu {
    border: 1px solid ${(props) => props.theme.color.BASE_400}60;
    border-radius: 6px;
    width: 350px;
    padding: ${ThemeSpace()[16]} ${ThemeSpace()[8]};
    margin: ${ThemeSpace()[8]} 0;
    display: flex;
    cursor: pointer;

    &:hover {
      border-color: ${(props) => props.theme.color.BASE_500} !important;
    }
  }

  .active {
    border-color: ${ThemeColor().PRIMARY} !important;
  }

  .heading-menuList {
    padding-left: ${ThemeSpace()[20]};
  }
`;

const AwProfileMenuList = ({ activeIndex = null }) => {

  let location = useLocation();
  const path = location?.pathname;
  let MenuList = [

    { icon: <BorderInnerOutlined />, heading: "Home", link: path.startsWith('/m') ? "/manage-course" : "/kp" },
    {
      icon: <UserOutlined />,
      heading: "My Profile",
      link: "/edit-profile",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    setCurrentIndex(activeIndex);
  }, [activeIndex]);
  return (
    <ProfileMenuList>
      {MenuList.map((item, index) => (
        <Link to={item.link}>
          <div
            className={`containerMenu ${
              currentIndex === index ? "active" : ""
            }`}
          >
            {item.icon} <div className="heading-menuList">{item.heading}</div>
          </div>
        </Link>
      ))}
    </ProfileMenuList>
  );
};

export default AwProfileMenuList;
