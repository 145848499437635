const initialState = {
  list: [],
};

const ManageHomeResponse = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HOME_RESPONSE":
      return {
        ...state,
        list: action.data.list,
      };

    default:
      return state;
  }
};

export default ManageHomeResponse;
