import { combineReducers } from "redux";
import ManageHomeResponse from "./home";
import LoadingStateReducer from "./loading-state";
import ManageUserDeatailsResponse from "./auth";

const rootReducer = combineReducers({
  homePageData: ManageHomeResponse,
  loadingState: LoadingStateReducer,
  userData: ManageUserDeatailsResponse,
});

export default rootReducer;
