import React, { lazy, Suspense, useEffect } from "react";
import {
  Navigate,
  useRoutes,
} from "react-router-dom";
import RouteEnum from "./assets/constants/RouteEnum";
import ProtectedRoutes from "./ProtectedRoutes";
import Layout from "./components/Layout/Layout";
import Loader from "./components/AtomicDesign/Atom/Loader"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AllHomeResponse } from "../src/redux/actions/home";
import { message } from "antd";

import {
  GetAllHomeData
} from "../src/services/home";
import Analytics from "./screens/Analytics";
import RevenueDashboard from "./screens/Revenue";

const CourseUpload = lazy(() => import("./screens/Course/CourseUpload"));
const Course = lazy(() => import("./screens/Course/index.tsx")) ;
const Login = lazy(() => import("./components/ExpertAdminLogin/LoginForm"));
const SignupForm = lazy(() => import("./components/ExpertAdminLogin/SignupForm"));
const Profile = lazy(() => import("./screens/MyProfile"));
const EditProfile = lazy(() => import("./screens/EditProfile/EditProfile"));
const OtpForm = lazy(() => import("./components/ExpertAdminLogin/OtpForm"));

const CreateProgram = lazy(() => import("./screens/KCommerceSuite/CreateProgram"));
const CreateAssessTalent = lazy(() => import("./screens/KCommerceSuite/CreateAssessTalent"));
const CreateConsultationService = lazy(() => import("./screens/KCommerceSuite/CreateConsultationService"));
const CreateCoachingService = lazy(() => import("./screens/KCommerceSuite/CreateCoachingService"));
const CreateAndLaunch = lazy(() => import("./screens/KCommerceSuite/CreateAndLaunch"));
const EarningWithAW = lazy(() => import("./screens/KCommerceSuite/EarningWithAW"));
const ManageOperations = lazy(() => import("./screens/KCommerceSuite/ManageOperations"));

const PayItForward = lazy(() => import("./screens/ThoughtsAcademySuite/PayForward"));
const TrendingOnTrails = lazy(() => import("./screens/ThoughtsAcademySuite/TrendingOnTrails"));
const YourTrails = lazy(() => import("./screens/ThoughtsAcademySuite/YourTrails"));

const PublicView = lazy(() => import("./screens/PublicView"));
const PublicTrendingOnTrails = lazy(() => import("./screens/TrendingOnTrails/Home"));
const BulkUpload  = lazy(() =>import("./screens/Course/BulkUpload"));

export const NavigationRoutes = () => {
  const approute = useRoutes(routes());
  return approute;
};


const routes = () =>{
  const navigate = useNavigate();

  const id = useSelector((state) => state?.userData?.data)|| localStorage.getItem("user_id")
  const dispatch = useDispatch();
  const onload = async () => {
    const apiResp = await dispatch(GetAllHomeData(id));
    if (apiResp?.status === 200) {
      dispatch(AllHomeResponse(apiResp?.data.data));
      if (apiResp?.data?.data?.contentPartnerDetails?.contentPartnerAuthorDetails?.id) {
        navigate(RouteEnum.COURSE);
      }
    } else {
      message.error("Something went wrong. Please try again");
    }
  };

  useEffect(() => {
    if(id){
      onload()
    }
  },[id])

return [
  {
    key: 'home',
    path: RouteEnum.HOME,
    element: <ProtectedRoutes>
      <Suspense fallback={<Loader />}>
        <Layout>
          <Profile />
        </Layout>
      </Suspense >
    </ProtectedRoutes>
  },
  {
    key: 'edit-profile',
    path: RouteEnum.EDIT_PROFILE,
    element: <ProtectedRoutes >
      <Suspense fallback={<Loader />}>
        <Layout>
          <EditProfile />
        </Layout>
      </Suspense>
    </ProtectedRoutes>,
  },
  {
    key: 'author-public-view',
    path: RouteEnum.PUBLIC_AUTHOR_PROFILE_VIEW,
    element: <ProtectedRoutes >
      <Suspense fallback={<Loader />}>
        <Layout>
          <PublicView />
        </Layout>
      </Suspense>
    </ProtectedRoutes>,
  },
  {
    key: 'create-and-launch',
    element: <ProtectedRoutes >
    </ProtectedRoutes>,
    children: [
      {
        key: 'create-and-launch',
        path: RouteEnum.CREATE_AND_LAUNCH,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <CreateAndLaunch />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'create-program',
        path: RouteEnum.CREATE_PROGRAM,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <CreateProgram />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'create-assess-talent',
        path: RouteEnum.CREATE_ASSESS_TALENT,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <CreateAssessTalent />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'create-coaching-service',
        path: RouteEnum.CREATE_COACHING_SERVICE,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <CreateCoachingService />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'create-consultation-service',
        path: RouteEnum.CREATE_CONSULTATION_SERVICE,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <CreateConsultationService />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'manage-operations',
        path: RouteEnum.MANAGE_OPERATIONS,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <ManageOperations />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'earning-with-antwalk',
        path: RouteEnum.PAYMENT_AND_INVOICE,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <EarningWithAW />
            </Layout>
          </Suspense>
        ),
      },
      // Redirect in case of no url match
      { path: "*", element: <Navigate to={RouteEnum.CREATE_AND_LAUNCH} replace /> },
    ],
  },

  {
    key: 'thought-academy-suite',
    element: <ProtectedRoutes >
    </ProtectedRoutes>,
    children: [
      {
        key: 'your-trails',
        path: RouteEnum.YOUR_TRAILS,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <YourTrails />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'pay-it-forward',
        path: RouteEnum.PAY_IT_FORWARD,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <PayItForward />
            </Layout>
          </Suspense>
        ),
      },
      {
        key: 'trending-on-trails',
        path: RouteEnum.TRENDING_ON_TRAILS,
        element: (
          <Suspense fallback={<Loader />}>
            <Layout>
              <TrendingOnTrails />
            </Layout>
          </Suspense>
        ),
      },
      // Redirect in case of no url match
      { path: "*", element: <Navigate to={RouteEnum.YOUR_TRAILS} replace /> },
    ],
  },
  {
    key: 'course',
    path: RouteEnum.COURSE,
    element: <ProtectedRoutes>
      <Suspense fallback={<Loader />}>
        <Layout>
          <Course />
        </Layout>
      </Suspense >
    </ProtectedRoutes>
  },
  {
    key: 'course-config',
    path: RouteEnum.COURSE_CONFIG,
    element: <ProtectedRoutes>
      <Suspense fallback={<Loader />}>
        <Layout>
          <CourseUpload />
        </Layout>
      </Suspense >
    </ProtectedRoutes>
  },
  {
    key: 'course-upload',
    path: RouteEnum.COURSE_UPLOAD,
    element: <ProtectedRoutes>
      <Suspense fallback={<Loader />}>
        <Layout>
          <BulkUpload />
        </Layout>
      </Suspense >
    </ProtectedRoutes>
  },
  {
    key: 'analytics',
    path: RouteEnum.ANALYTICS,
    element: <ProtectedRoutes>
      <Suspense fallback={<Loader />}>
        <Layout>
          <Analytics />
        </Layout>
      </Suspense >
    </ProtectedRoutes>
  },
  {
    key: 'revenue',
    path: RouteEnum.REVENUE,
    element: <ProtectedRoutes>
      <Suspense fallback={<Loader />}>
        <Layout>
          <RevenueDashboard />
        </Layout>
      </Suspense >
    </ProtectedRoutes>
  },
  // unprotected routes
  {
    key: 'login',
    path: RouteEnum.LOGIN,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    key: 'login-otp',
    path: RouteEnum.LOGIN_OTP,
    element: (
      <Suspense fallback={<Loader />}>
        <OtpForm />
      </Suspense>
    ),
  },
  {
    key: 'sign-up',
    path: RouteEnum.SIGN_UP,
    element: (
      <Suspense fallback={<Loader />}>
        <SignupForm />
      </Suspense>
    ),
  },
  {
    key: 'public-trending-on-trails',
    path: RouteEnum.PUBLIC_TRENDING_ON_TRAILS,
    element: (
      <Suspense fallback={<Loader />}>
        <PublicTrendingOnTrails />
      </Suspense>
    )
  },
  { path: "*", element: <Navigate to={RouteEnum.LOGIN} replace /> },
]} ;

export default routes;
