import CourseIcon from "./CourseIcon.svg";
import PlayIcon from "./PlayIcon.svg";
import WishlistIcon from "./WishlistIcon.svg";
import FolderIcon from "./FolderIcon.svg";
import VideoIcon from "./VideoIcon.svg";
import AddIcon from "./AddIcon.svg";
import OrangeVideoIcon from "./OrangeVideoIcon.svg";
import EditWithSquareBlue from "./EditWithSquareBlue.svg";
import NoDataWithUser from "./NoDataWithUser.svg";
import DeleteRedIcon from "./DeleteRedIcon.svg";
import OrangeVideo from "./OrangeVideo.svg";
import OrangeFile from "./OrangeFile.svg";
import UnactiveListView from "./UnactiveListView.svg";
import UnactiveCardView from "./UnactiveCardView.svg";
import ActiveListView from "./ActiveListView.svg";
import ActiveCardView from "./ActiveCardView.svg";
import OrangeBook from "./OrangeBook.svg"
import OrangeFiles from "./OrangeFiles.svg";
import OrangeLive from "./OrangeLive.svg"
import OrangeCalender from "./OrangeCalender.svg"
import SampleCsv from './SampleCsv.svg';
import RightArrowBlue from "./RightArrowBlue.svg"
import LiveIcon from "./LiveIcon.svg"
import BlackLive from "./BlackLive.svg"
import BlueVideoIcon from "./BlueVideoIcon.svg";
import EyeIcon from "./EyeIcon.svg";
import OrganisationIcon from "./OrganisationIcon.svg";
import TimeIcon from "./TimeIcon.svg";
import UserIcon from "./UserIcon.svg";
import BlueCourseIcon from "./BlueCourseIcon.svg"
import BlueLink from "./BlueLink.svg"
import EditIcon from "./EditIcon.svg"
import RupeeIcons from "./RupeeIcons.svg"

export default {
  CourseIcon,
  PlayIcon,
  WishlistIcon,
  FolderIcon,
  VideoIcon,
  AddIcon,
  OrangeVideoIcon,
  EditWithSquareBlue,
  NoDataWithUser,
  DeleteRedIcon,
  OrangeVideo,
  OrangeFile,
  UnactiveListView,
  UnactiveCardView,
  ActiveListView,
  ActiveCardView,
  OrangeBook,
  OrangeFiles,
  OrangeLive,
  OrangeCalender,
  SampleCsv,
  RightArrowBlue,
  LiveIcon,
  BlackLive,
  BlueVideoIcon,
  EyeIcon,
  OrganisationIcon,
  TimeIcon,
  UserIcon,
  BlueCourseIcon,
  BlueLink,
  EditIcon,
  RupeeIcons
};
