import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ThemeColor } from "../../../assets/theme";
import NotificationBell from "../../../assets/icons/NotificationBell.svg";

const AwNotificationBell = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const unRead = useRef(0);

//   const getNotifications = async () => {
//     //   const res = await ApiRequest.get('/api/v6/notifications?page=1&per-page=2');
//     //   if (res?.data?.success) {
//     //     unRead.current = res?.data?.data?.meta?.unread;
//     //     const temp = res.data?.data?.rows?.map((item) => ({
//     //       ...item,
//     //       time: showTime(item?.sendDate),
//     //     }));
//     //     setNotifications(temp);
//     //   }
//     };

    const onClickOutside = (e) => {
      let specifiedElement = document.getElementById('notification_dropdown');
      !specifiedElement?.contains(e.target) && setShowNotification(false);

    };

    useEffect(() => {
        document.addEventListener('click', onClickOutside);
        // getNotifications();
        return () => document.removeEventListener('click', onClickOutside);
      }, []);

  //   const showTime = (time) => {
  //     const currentTime = new Date().getTime() / 1000;
  //     const notificationTime = new Date(time).getTime() / 1000;
  //     const difference = currentTime - notificationTime;
  //     let relativeTime = '';

  //     if (difference < 60) {
  //       return 'few seconds ago';
  //     } if (difference < 3600) {
  //       relativeTime = Math.floor(difference / 60);
  //       return `${relativeTime} ${relativeTime === 1 ? 'minute' : 'minutes'} ago`;
  //     } if (difference < 86400) {
  //       relativeTime = Math.floor(difference / 3600);
  //       return `${relativeTime}  ${relativeTime === 1 ? 'hour' : 'hours'} ago`;
  //     } if (difference < 2620800) {
  //       relativeTime = Math.floor(difference / 86400);
  //       return `${relativeTime} ${relativeTime === 1 ? 'day' : 'days'} ago`;
  //     } if (difference < 31449600) {
  //       relativeTime = Math.floor(difference / 2620800);
  //       return `${relativeTime} ${relativeTime === 1 ? 'month' : 'months'} ago`;
  //     }
  //     relativeTime = Math.floor(difference / 31449600);
  //     return `${relativeTime} ${relativeTime === 1 ? 'year' : 'years'} ago`;
  //   };

  //   const getNotifications = async () => {
  //     const res = await ApiRequest.get('/api/v6/notifications?page=1&per-page=2');
  //     if (res?.data?.success) {
  //       unRead.current = res?.data?.data?.meta?.unread;
  //       const temp = res.data?.data?.rows?.map((item) => ({
  //         ...item,
  //         time: showTime(item?.sendDate),
  //       }));
  //       setNotifications(temp);
  //     }
  //   };

   

  //   const handleView = async (item) => {
  //     const { lpSlug, extraData, id } = item;
  //     const { CompetencyId, ModuleId, InterventionId } = extraData.payload;
  //     await ApiRequest.patch(`/api/v6/notifications?ids[]=${[id]}`);
  //     router.push(
  //       `/lxp/${lpSlug}/comp_${CompetencyId}?module-id=${ModuleId}&intervention-type=${extraData?.path
  //         ?.replaceAll(' ', '-')
  //         .toLowerCase()}&intervention-id=${InterventionId}`,
  //     );
  //   };

  //   const handleMark = async () => {
  //     const res = await ApiRequest.patch('/api/v6/notifications?ids[]=[-1]');
  //     if (res?.data?.success) {
  //       getNotifications();
  //     }
  //   };

  return (
    <MenuDropdown id="notification_dropdown">
      {Boolean(unRead.current) && (
        <span className="number">{unRead.current}</span>
      )}
      <img
        src={NotificationBell}
        alt="NotificationBell"
        className="bell"
        onClick={() => setShowNotification(!showNotification)}
      />

      {showNotification && (
        <div className="dropdown_container profile_dropdown">
          {notifications.length > 0 ? (
            <div className="dropdown">
              <div className="header">
                <span>Notifications</span>
                {Boolean(unRead) && (
                  <span onClick={handleMark}>Mark all as read</span>
                )}
              </div>

              <div className="notification-ctn">
                {notifications.map((item) => (
                  <div
                    className={`notification ${
                      item.status === "UNREAD" && "unread"
                    }`}
                    key={item.id}
                  >
                    <div className="details">
                      {item.status === "UNREAD" && (
                        <span className="red-circle" />
                      )}
                      {/* <img src='' alt="icon" /> */}
                      <div className="title-ctn">
                        <span className="title">{item?.subtitle}</span>
                        <span>{item?.text?.split(" ", 11).join(" ")}</span>
                      </div>
                    </div>
                    <div className="view">
                      <button onClick={() => handleView(item)}>View</button>
                      <span className="time">{item.time}</span>
                    </div>
                  </div>
                ))}
              </div>
              <button
                // onClick={() => router.push('/notification')}
                className="view-all"
              >
                View All
              </button>
            </div>
          ) : (
            <div className="dropdown no-notification">
              No New Notifications!!
            </div>
          )}
        </div>
      )}
    </MenuDropdown>
  );
};

export default AwNotificationBell;

const MenuDropdown = styled.div`
  position: relative;

  .number {
    position: absolute;
    padding: 3px 5px;
    border-radius: 50%;
    background: #da1e28;
    color: #ffffff;
    font-size: 12px;
    right: -5px;
    top: -8px;
  }

  .dropdown_container {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
  }
  .dropdown {
    background: ${ThemeColor().WHITE};
    box-shadow: 1px 1px 15px 0px ${ThemeColor().GRAY_60};
    border-radius: 8px;
    padding: 8px 0px;
    margin-top: 12px;

    font-family: "Poppins";
    font-style: normal;
  }

  .bell {
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 18px;
    width: 500px;
    span:first-child {
      font-weight: 600;
      color: #000000;
      opacity: 0.7;
    }

    span:last-child {
      font-weight: 400;
      color: #0f4d91;
      cursor: pointer;
      font-size: 16px;
    }
  }

  .notification-ctn {
    padding-bottom: 20px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }

  .notification {
    padding: 20px 10px;
    .details {
      display: flex;
      gap: 20px;
      position: relative;

      img {
        margin-left: 20px;
        align-self: start;
      }
    }
    .red-circle {
      position: absolute;
      top: 15px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #da1e28;
    }
    .title-ctn {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-weight: 400;
      .title {
        font-weight: 600;
      }
    }
    .view {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      button {
        margin-left: 70px;
        padding: 8px 16px;
        background: #ffffff;
        border: 1px solid #228ac2;
        box-shadow: 0px 0px 10px rgba(34, 138, 194, 0.25);
        border-radius: 56px;
        color: #228ac2;
      }
      span {
        color: #676767;
      }
    }
  }

  .unread {
    background: #d8e9f3;
  }

  .view-all {
    display: block;
    padding: 10px 20px;
    background: #228ac2;
    box-shadow: 0px 10px 20px rgba(34, 138, 194, 0.4);
    border-radius: 56px;
    color: #ffffff;
    margin: 10px auto;
  }

  .no-notification {
    width: 250px;
    padding: 20px;
    font-size: 18px;
  }
`;
