import styled from "styled-components";
import colors from "../../../assets/color";
import { ThemeColor } from "../../../assets/theme";

export const HomeWrapper = styled.div`
  position: fixed;
  top: ${(props) => `${props?.topOffset || 0}px`};
  z-index: 200;
  width: 100%;
  background-color: #fff;
  height: 60px;
  border-bottom: 1px solid #efefef;

  .__enterprise {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .__enterprise_menu {
      display: flex;
      a {
        font-size: 1rem;
        color: ${(props) => props.theme.color.ant_secondary};
        margin-right: 0.6rem;
        padding: 2rem 0 1.5rem 0;
        &:hover {
          color: ${(props) => props.theme.color.ant_secondary};
          font-weight: bold;
        }
      }
      .__active {
        color: ${(props) => props.theme.color.ant_secondary};
        font-weight: bold;
        border-bottom: 2px solid ${(props) => props.theme.color.ant_secondary};
      }
    }
  }

  .user-menu-container {
    margin-left: 20px;
    cursor: pointer;
    .user-wrapper {
      display: flex;
      align-items: center;
      .icon {
        background-color: #fff;
        border-radius: 50%;
      }
    }
    .user-details {
      display: flex;
      flex-direction: column;
      margin-left: 0.5rem;
      span:nth-child(1) {
        font-weight: 500;
        font-size: 1rem;
        color: ${colors.PRIMARY};
        white-space: nowrap;
      }
      span:nth-child(2) {
        font-weight: normal;
        font-size: 0.875rem;
        color: #888888;
        margin-top: 0.25rem;
      }
    }
  }

  .left-container {
    height: 100%;
    display: flex;
    align-items: center;
    width: 240px;
    @media (max-width: 1060px) {
      width: 100%;
    }

    .search-container {
      flex: 1;
      padding-left: 15px;
      margin: 0 30px 0 12px;
      @media (max-width: 700px) {
        flex: none;
        padding-left: 0px;
      }
    }

    .antwak-logo {
      padding-left: 0.5rem;
      cursor: pointer;
    }
  }

  .TalkBtn {
    display: none;
    @media (max-width: 1060px) {
      display: block;
    }
  }

  .middle-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @media (max-width: 1060px) {
      display: none;
    }
    .default-menu {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .sub-title {
      margin: 0.5rem;
      height: 100%;
      padding: 10px 12px;
      font-size: 1.1rem;
      line-height: 1.5;
      font-weight: 500;
      color: #000;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        color: ${colors.PRIMARY_RED};
      }
    }
    .active {
      border-bottom: 2px solid ${colors.PRIMARY_RED};
      color: ${colors.PRIMARY_RED};
    }
  }

  .__applyNow {
    background-color: rgb(255, 255, 255);
    border-radius: 0.25rem;
    color: rgb(1, 87, 155);
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid rgb(1, 87, 155) !important;
    cursor: pointer;
    margin-right: 12px;
  }

  .demo-btn {
    height: 2rem;
    padding: 0 1rem;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    white-space: nowrap;
    width: 180px;
    color: rgb(255, 255, 255) !important;
    background-color: rgb(191, 72, 72) !important;
    border-color: rgb(191, 72, 72) !important;
  }

  .demo-btn:hover {
    background-color: rgb(191, 72, 72) !important;
    border-color: rgb(191, 72, 72) !important;
  }

  .last-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: clamp(10px, 2.5vw, 30px);
  }

  .menu-container {
    display: none;
    margin-right: 10px;
    margin-left: 12px;
    @media (max-width: 1060px) {
      display: flex;
    }
  }

  .header-container {
    width: 100%;
    margin: 0px auto;
    height: 100%;
    padding: 0 2.5rem;
    @media screen and (max-width: 618px) {
      padding: 0;
    }
  }

  .login_cta {
    color: ${ThemeColor().SECONDARY};
    font-weight: 600;
    cursor: pointer;
  }

 
  .ant-switch {
    background-color: #8E8E8E !important;
  }
  .ant-switch-checked {
    background-color: #2797d3 !important;
  }
`;
