import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const HowItsCalculated = () => {
    const calculationItems = [
        {label:'Revenue pool',value:'25,00,000'},
        {label:'Consumption %',value:'40%'},
        {label:'Revenue Share',value:'30%',equalSign:true},
        {label:'Payout for the month ',value:'3,00,000',isAnswer:true},
    ]

  return (
    <HowCalculatedModalWrapper>
            <Heading>
            How Its Calculated:
            </Heading>
            <div className="calculationContainer">
                    {
                        calculationItems?.map((item) => {
                            return <div className='calculationItem' key={item.label}>
                               <div className={item.isAnswer ? 'active' : ''}>
                               <p className='label'>{item?.label}</p>
                               <span>{`(${item.value})`}</span>
                               </div>
                                <p className='sign'>{!item.isAnswer && (item?.equalSign ? '=' : 'x')}</p>
                            </div>
                        })
                    }
            </div>
    </HowCalculatedModalWrapper>
  )
}

const HowCalculatedModalWrapper = styled.div`
    ${tw`bg-white text-black p-[5px]`}
    
    .calculationContainer {
        ${tw`flex gap-3`}
    }
    .calculationItem {
        ${tw`flex flex-row gap-2 items-start`}
    }
    .calculationItem div {
        ${tw`flex flex-col`}
    }
    
    .calculationItem .label {
        ${tw`min-w-fit text-[#2B2B2B] text-[12px]`}
    }
    .calculationItem .sign {
        ${tw`text-[14px] mt-[-3px]`}
    }
    .calculationItem span {
        ${tw`min-w-fit text-[#A7A7A7] text-[12px] italic `}
    }
    .calculationItem .active * {
        ${tw`text-[#228AC2] font-semibold`}
    }
`
const Heading = styled.h2`
    ${tw`text-[#0F4D91] font-semibold text-[12px]`}
`

export default HowItsCalculated