import { Table } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import RevenueDetailCard from "./RevenueDetailCard";
import PayoutForMonth from "./PayoutForMonth";
import { useSelector } from "react-redux";

const RevenueDetails = ({ revenueShareData,isLoading }: { revenueShareData: any ,isLoading:boolean}) => {
  const [tableData, setTableData] = useState<
    { component: string; value: number | string }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = [
    {
      title: "Components",
      dataIndex: "component",
      key: "component",
    },
    {
      title: (
        <p>
          Values <span className="label">{"(for this month)"}</span>
        </p>
      ),
      dataIndex: "value",
      key: "value",
    },
  ];

  function formatData(components: string[], values: string[]) {
    const data = components.map((component, index) => {
      return {
        component: component,
        value: values[index] || 0,
      };
    });

    return data;
  };
  const contentPartnerName = useSelector((state:any) => state.homePageData.list.contentPartnerDetails.cpName) as string;

  const rightSectionData = [
    {
      label:`Revenue Pool for AntWalk and ${contentPartnerName}`,
      value:revenueShareData?.revenuePoolForAntwalkAndBergerPaints
    },
    {
      label:'Revenue Share',
      span:'in percentage',
      value:revenueShareData?.revenueShare
    },
    {
      label:'Your Payout for the month',
      info:true,
      value:revenueShareData?.yourPayoutForTheMonth
    }

  ]

  useEffect(() => {
    setTableData(
      formatData(
        revenueShareData?.components || [],
        revenueShareData?.values || []
      )
    );
  }, [revenueShareData]);
  return (
    <RevenueDetailsWrapper>
      <RevenueDetailsTable
        columns={columns}
        dataSource={tableData}
        pagination={false}
        loading={isLoading}
      />
      <RevenueRightWrapper>
        {
          rightSectionData?.map((data,index) => <RevenueDetailCard key={index} data={data} setIsModalOpen={setIsModalOpen}/>)
        }
    
      </RevenueRightWrapper>
      <PayoutForMonth
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
      />
    </RevenueDetailsWrapper>
  );
};

const RevenueDetailsWrapper = styled.div`
  ${tw`w-[100%] flex flex-row gap-x-4 bg-white p-5`}
`;
const RevenueDetailsTable = styled(Table)`
  ${tw`w-[70%] border border-solid border-[#e9e9e9]`}
  .ant-table-thead > tr > th {
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;
  }
  tbody tr {
    border: 1px solid #e9e9e9;
  }
  thead tr .ant-table-cell {
    ${tw`font-semibold text-[14px] text-[#0F4D91]`}
  }
  td {
    ${tw`font-semibold text-[#191919] text-[14px]`}
  }
  .label {
    ${tw`font-normal italic`}
  }
`;
const RevenueRightWrapper = styled.div`
  ${tw`flex flex-col justify-between`}
`;

export default RevenueDetails;
