const initialState = {
  userTokenData: [],
  data: null,
};

const ManageUserDeatailsResponse = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOKEN":
      return {
        ...state,
        userTokenData: action.data.data,
      };

    case "GET_USER_ID":
      return {
        ...state,
        data: action.data.data,
      };

    default:
      return state;
  }
};

export default ManageUserDeatailsResponse;
