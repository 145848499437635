import React from "react";
import styled from "styled-components";
import { Heading } from "..";
import { Select } from "antd";
import tw from "twin.macro";
import RevenueDetails from "./RevenueDetails";
import { getCurrentMonthAndYear } from "../config";

const RevenueShare = ({revenueShareData,isLoading}:{revenueShareData:any,isLoading:boolean}) => {
  const options = [
    {
      value:getCurrentMonthAndYear(),
      label: new Date().toLocaleString('default', { month: 'long', year: 'numeric' })
    },
  ];
  return (
    <RevenueShareWrapper>
      <div className="header">
        <Heading>Revenue Share</Heading>
        <Select className="dateFilter" defaultValue={options[0].value} options={options} />
      </div>
      <RevenueDetails revenueShareData={revenueShareData} isLoading={isLoading}/>
    </RevenueShareWrapper>
  );
};

const RevenueShareWrapper = styled.div`
  .dateFilter .ant-select-selector {
    ${tw`!rounded-full px-5 border-none shadow-md`}
  }

  .header {
    ${tw`flex justify-between items-center py-6 `}
  }
`;

export default RevenueShare;
