import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const SummaryCard = ({ summary }: { summary: any }) => {
  return (
    <SummaryCardWrapper>
      <div className="leftBar">
        <div className="imageContainer">
          <img src={summary?.icon} alt="" />
        </div>
        <div className="label">{summary?.label}</div>
      </div>
      <span className="value">{summary?.value}</span>
    </SummaryCardWrapper>
  );
};

const SummaryCardWrapper = styled.div`
  ${tw`bg-white w-full h-[104px] flex flex-row items-center justify-between p-3 px-5 rounded-md shadow-sm`}
  .leftBar {
    ${tw`flex flex-row gap-3`}
  }
  .label {
    ${tw`text-[#4E4E4E] font-semibold text-[16px]`}
  }

  .label span {
    ${tw`text-[14px] font-medium text-[#8B8B8B]`}
  }
  .value {
    ${tw`text-[#404040] font-bold text-[28px]`}
  }

  .imageContainer {
    ${tw`bg-[#EBF5FF] p-3 h-fit w-fit rounded-full`}
    img {
      ${tw`w-[29px] h-[29px]`}
    }
  }
`;

export default SummaryCard;
