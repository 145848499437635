import React from "react";
import {
  BrowserRouter,
} from "react-router-dom";
// import logo from "./logo.svg";
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
import { NavigationRoutes } from "./Routes";
import "./App.css";
import styled from "styled-components";

import tw from "twin.macro";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme_config";
import dayjs from "dayjs";
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault(dayjs.tz.guess());

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme("seablue")}>
        <BrowserRouter>
          <NavigationRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;

export const FirstStyle = styled.div`
  text-decoration: underline;
  color: red;
  ${tw`font-bold text-lg mb-24`}
`;
