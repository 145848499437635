import { Modal, Pagination, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import revenueApis from "../../../services/revenue";
import { formatPayoutData } from "../config";
import { useSelector } from "react-redux";

const PayoutForMonth = ({
  isModalOpen,
  handleCancel,
}: {
  isModalOpen: boolean;
  handleCancel?: () => void;
}) => {

  // state
  const [data, setData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    total: 1,
    current: 1,
  });
  const[isLoading,setIsLoading] = useState(true)
  const [filterdArray, setFilteredArray] = useState([]);
  const contentPartnerName = useSelector((state:any) => state.homePageData.list.contentPartnerDetails.cpName) as string;

  const payoutColumns = [
    {
      title: "Enterprise",
      dataIndex: "enterprise",
      key: "enterprise",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
    },
    {
      title: <Tooltip title='ARPU: Average Revenue per user'>ARPU for the month</Tooltip>,
      dataIndex: "arpu",
      key: "arpu",
    },
    {
      title: <span>
        Total Revenue <br /> for the month
      </span>,
      dataIndex: "totalRevenue",
      key: "totalRevenue",
    },
    {
      title: "Consumption Percentage",
      dataIndex: "consumptionPercentage",
      key: "consumptionPercentage",
    },
    {
      title: `Revenue Pool for AntWalk & ${contentPartnerName}`,
      dataIndex: "revenuePool",
      key: "revenuePool",
    },{
      title: "Revenue Sharing",
      dataIndex: "revenueSharing",
      key: "revenueSharing",
    },
    {
      title: "Acquired Revenue",
      dataIndex: "acquiredRevenue",
      key: "acquiredRevenue",
    }

  ]

  // to fetch data for month
  const getData = async () => {
    const res = await revenueApis.getPayoutForTheMonth("3");
    if (res) {
      const formattedData = formatPayoutData(res?.payoutForTheMonth) || [];
      setData(formattedData || []);
      const enterpriseList = [];
      let result = formattedData?.filter((item: any) => {
        if (item.enterprise) {
          enterpriseList.push(item.enterprise);
        }
        if (enterpriseList.length < 6) {
          return true;
        }
        return false;
      });
      setFilteredArray(result);
      setPaginationInfo({
        ...paginationInfo,
        total: enterpriseList.length,
      });
    }
    setIsLoading(false)
  };

  // for handling pagination in frontend only
  const handlePagination = (page: number) => {
    const enterpriseList = [];
    const enterpriseToSkip = page == 1 ? 0 : page * 5 - 5;
    const limitEnterprise = [];

    let result = data?.filter((item: any) => {
      if (item?.enterprise) {
        enterpriseList.push("1");
      }
      if (enterpriseList.length <= enterpriseToSkip && enterpriseToSkip) {
        return false;
      }

      if (item.enterprise) {
        limitEnterprise.push(item.enterprise);
      }
      if (limitEnterprise.length < 6) {
        return true;
      }
      return false;
    });
    setPaginationInfo({
      ...paginationInfo,
      current: page,
    });
    setFilteredArray(result);
  };

  useEffect(() => {
    if(isModalOpen){
      getData();
    }
  }, [isModalOpen]);

  return (
    <PayoutForMonthModal
      footer={null}
      width={"95vw"}
      open={isModalOpen}
      title="Payout for the month"
      onCancel={handleCancel}
    >
      <PayoutTable
        dataSource={filterdArray}
        scroll={{
          x: true,
          y: 400,
          
        }}
        loading={isLoading}
        columns={payoutColumns}
        pagination={false}
      />
      <div className="paginationContainer">
        <Pagination
          {...{
            pageSize: 5,
            showSizeChanger: false,
            ...paginationInfo,
            onChange: handlePagination,
            showTotal: (total, [start, end]) => {
              return (
                <p>
                  Showing <span className="highlight">{start}</span> to{" "}
                  <span className="highlight">{end}</span> of{" "}
                  <span className="highlight">{total}</span> results
                </p>
              );
            },
          }}
        />
      </div>
    </PayoutForMonthModal>
  );
};

const PayoutForMonthModal = styled(Modal)`
  .ant-modal-header {
    padding: 20px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    ${tw`max-h-[80vh] top-0  bg-white rounded-lg items-center justify-center`}
    bottom: 10px;
    padding: 0px !important;
  }

  .paginationContainer {
    ${tw`py-4 flex justify-end px-4`}
  }
  .highlight {
    ${tw`text-black font-semibold`}
  }
`;
const PayoutTable = styled(Table)`
  ${tw`border border-solid border-[#e9e9e9]`}

  .ant-table-thead > tr > th {
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;
  }
  tbody tr {
    border: 1px solid #e9e9e9;
  }
  thead tr .ant-table-cell {
    ${tw`font-semibold text-[14px] text-[#0F4D91]`}
  }
  td {
    ${tw`font-semibold text-[#191919] text-[14px]`}
  }
  .label {
    ${tw`font-normal italic`}
  }
`;

export default PayoutForMonth;
