import React, { useEffect, useState } from 'react'
import Summary from './Summary'
import styled from 'styled-components'
import tw from 'twin.macro'
import AnalyticsTable from './AnalyticsTable'
import { IAnalyticsData } from './utils'
import { useSelector } from 'react-redux'
import { TContentPartnerId } from '../Course'
import analyticsApis from "../../services/analytics/index"
import WarningDummyData from '../../components/AtomicDesign/Molecule/WarningDummyData'

const Analytics = () => {
  const [analyticsData,setAnalyticsData] = useState<IAnalyticsData>();
  const [isLoading,setIsLoading] = useState(true);
  const contentPartnerId = useSelector<TContentPartnerId>((state) => state.homePageData.list.contentPartnerDetails.id) as string;


  const getAnalyticsData = async() => {
    const data = await analyticsApis.getAnalyticsData(contentPartnerId);
    if(data){
      setAnalyticsData(data);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getAnalyticsData()
  },[])
  return (
    <AnalyticsWrapper>
      <Heading>
        Summary
      </Heading>
     <div className='warningContainer'>
     <WarningDummyData/>
     </div>
      <Summary data={analyticsData?.summary}/>
      <AnalyticsTable data={analyticsData?.analytics} isLoading={isLoading}/>
    </AnalyticsWrapper>
  )
}

const AnalyticsWrapper = styled.div`
  margin-right:32px;
  margin-top:40px;
  position:relative;
  .warningContainer {
    ${tw`w-full absolute flex justify-center top-[-20px]`}
  }
`

export const Heading = styled.h2`
  ${tw`text-[16px] font-semibold my-3 text-[#111928]`}
`

export default Analytics