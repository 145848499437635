import axios from "axios";
import congi from "../../config/properties";
const api = axios.create({
  baseURL: process.env.REACT_APP_CONSUMER_BACKEND_URL,
});

// Axios interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const jwtToken = localStorage.getItem("token");
        if (!refreshToken) {
          throw new Error("User not logged in or refresh token missing");
        }
        const response = await api.post(
          "/api/v4/expert/refresh-token",
          { token: refreshToken },
          {
            headers: {
              Authorization: jwtToken,
            },
          }
        );
        const newAccessToken = response.data?.data?.token?.token;
        localStorage.setItem("token", newAccessToken);
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (error) {
        // In case of error clear localStorage and send user to /
        localStorage?.clear();
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
