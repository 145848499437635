export const formatPayoutData = (data:any) => {
    let result:any = [];
    data.forEach((item:any) => {
      item?.users?.forEach((user:any,index:number) => {
        result.push({
          enterprise: index == 0 ? item.enterprise : '',
          users: user,
          arpu:item?.arpuForTheMonth[index] || '',
          totalRevenue:item?.totalRevenueforTheMonth[index] || '',
          consumptionPercentage:item?.consumptionPercentage[index] || '',
          revenuePool:item?.revenuePoolForAntwalkAndBergerPaints[index] || '',
          revenueSharing:item?.revenueSharing[index] || '',
          acquiredRevenue:item?.acquiredRevenue[index] || '',
        });
      });
    });

    return result
  }  

 export const getCurrentMonthAndYear = () => {
    const date = new Date();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${year}`;
  };