import React, { useEffect, useState } from "react";
import { HomeWrapper } from "./style";
import { MenuOutlined } from "@ant-design/icons";
import AwNotificationBell from "../../AtomicDesign/Molecule/AwNotificationBell";
import AwProfileMenuDropdown from "../../AtomicDesign/Molecule/AwProfileMenuDropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Switch } from "antd";
import color from "../../../assets/color";
import {
  GetAllHomeData
} from "../../../services/home";
import { AllHomeResponse } from "../../../redux/actions/home";
import { getAuthorId } from "../../../services/auth";
import { GetUserId } from "../../../redux/actions/auth";
import RouteEnum from "../../../assets/constants/RouteEnum";

const Header = (props) => {
  let location = useLocation();
  const path = location?.pathname;
  const targetPath = path.startsWith('/m') ? '/manage-course' : '/kp';
  const data = useSelector((state) => state?.homePageData);
  const imageSrc = data?.list?.photo?.photo;
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigation = useNavigate();
  const [currentRouteContent,setCurrentRouteContent] = useState(targetPath != '/kp');
  const[isContentPartner,setIsContentPartner] = useState(false)

  const toggleCurrentRoute = () => {
    if(currentRouteContent){
      navigation("/kp")
    }else{
      navigation("/manage-course")
    }
    setCurrentRouteContent(!currentRouteContent)
  }

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const antwalkUserId = localStorage.getItem('antwalk_user_id');
    if (token && antwalkUserId && !imageSrc) {
      const authPayload = {
        userId: antwalkUserId,
      };
      const apiAuth = await getAuthorId(authPayload, token);
      if (apiAuth?.function?.length > 0 && apiAuth?.function[0]?.author_id) {
        dispatch(GetUserId(apiAuth?.function[0]?.author_id));
        const apiResp = await dispatch(GetAllHomeData(apiAuth?.function[0]?.author_id));
        if (apiResp?.status === 200) {
          dispatch(AllHomeResponse(apiResp?.data.data));
          if(apiResp?.data?.data?.contentPartnerDetails?.id){
            setIsContentPartner(true)
          }
        }
        setIsAuthenticated(true);
      }
    }
  }

  // In case of Un Protected routes by header exists fetch user data before showing screen
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {props?.hideHeader === true ? null : (
        <HomeWrapper id="main-header" topOffset={props?.topOffset || 0}>
          <div className="header-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="left-container">
                <div className="logo-container">
                  <Link to={targetPath}>
                    <img
                      alt="AntWalk - Experiential Learning logo"
                      className="antwak-logo"
                      id="antwak-logo"
                      src="https://cdn.antwalk.com/logo.webp"
                      width="150px"
                    />
                  </Link>
                </div>
              </div>
              <div className="last-container">
                {isAuthenticated ?
                  <>
                      {/* {
                        isContentPartner && <Switch checked={currentRouteContent} onChange={toggleCurrentRoute} checkedChildren="Content Partner" unCheckedChildren="Knowledge Partner" />
                      } */}
                    <AwNotificationBell />
                    <AwProfileMenuDropdown
                      imageSrc={imageSrc}
                    //   isLoggedIn={props?.isLoggedIn}
                    //   hasUserDetails={
                    //     JSON.parse(localStorage.getItem('aw_user'))?.name
                    //   }
                    />
                  </>
                  :
                  <Button
                    type="primary"
                    href={RouteEnum.LOGIN}
                    style={{
                      background: color.PRIMARY,
                    }}
                  >
                    Login
                  </Button>
                }
              </div>
            </div>
          </div>
        </HomeWrapper>
      )}
    </>
  );
};

export default Header;
