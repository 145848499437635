import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";

const UserIcon = styled.div`
  ${tw`text-lg font-semibold`};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 50%;
  display: grid;
  place-items: center;

  ${(props) =>
    props.type === "text"
      ? css`
          background: linear-gradient(180deg, #233862 0%, #2c59b1 100%);
          color: ${(props) => props.theme.color.WHITE};
          text-transform: uppercase;
        `
      : css`
          overflow: hidden;
          border: 1px solid ${(props) => props.theme.color.BASE_500};
          img {
            width: ${(props) => props.width};
            height: auto;
            min-height: ${(props) => props.height};
            object-fit: cover;
          }
        `}
`;

const AwUserIcon = ({
  type = "text",
  imgSrc,
  textValue = "AW",
  textSize = "1.3rem",
  width = "48px",
  height = "48px",
  handleClick,
}) => {
  if (type === "image") {
    return (
      <UserIcon
        onClick={handleClick}
        type={type}
        width={width}
        height={height}
        textSize={textSize}
      >
        <img
          src={!imgSrc ? "https://cdn.antwalk.com/antwak/userimg.png" : imgSrc}
        />
      </UserIcon>
    );
  }

  return (
    <UserIcon
      onClick={handleClick}
      type={type}
      width={width}
      height={height}
      textSize={textSize}
    >
      {textValue}
    </UserIcon>
  );
};

export default AwUserIcon;
