import React from "react";
import styled from "styled-components";
import { Heading } from ".";
import { Button, Input, Table } from "antd";
import tw from "twin.macro";
import { DownOutlined, DownloadOutlined, UpOutlined } from "@ant-design/icons";
import Icons from "../../Global/Icons";
import { CourseAnalytics } from "./utils";
import moment from "moment";

interface CustomExpandIconProps {
  isActive: boolean;
  onExpand: (record: any, event: React.MouseEvent<HTMLElement>) => void;
  record: any;
}
const AnalyticsTable = ({data,isLoading}:{data:CourseAnalytics[] | undefined,isLoading:boolean}) => {
  const columns = [
    {
      title: "Video Title",
      dataIndex: "title",
      key: "title",
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      render: (text: any, record: any) =>
        record?.children ? (
         <a href={record?.videoUrl || 'https://vimeo.com/984175023'} target="_blank"> <CourseTitle text={text} addedOn={record?.addedOn}/></a>
        ) : (
          <span className="childSource">{text}</span>
        ),
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "views",
      sorter: (a: any, b: any) => a.views - b.views,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      sorter: (a: any, b: any) => a.impressions - b.impressions,
    },
    {
      title: "Finishes",
      dataIndex: "finishes",
      key: "finishes",
      sorter: (a: any, b: any) => a.finishes - b.finishes,
    },
    {
      title: "Unique Users",
      dataIndex: "uniqueUsers",
      key: "uniqueUsers",
      sorter: (a: any, b: any) => a.uniqueUsers - b.uniqueUsers,
    },
    {
      title: "Unique Impression",
      dataIndex: "uniqueImpressions",
      key: "uniqueImpressions",
      sorter: (a: any, b: any) => a.uniqueImpressions - b.uniqueImpressions,
    },
    {
      title: "Total Watch Time (in Mins)",
      dataIndex: "totalWatchTime",
      key: "totalWatchTime",
      sorter: (a: any, b: any) => a.totalWatchTime - b.totalWatchTime,
    },
  ];

  const CustomExpandleIcon = ({
    isActive,
    onExpand,
    record,
  }: CustomExpandIconProps) => {
    return <div/>
    if (!Boolean(record?.children && record?.children?.length)) return null;
    return (
      <CustomExpandleIconWrapper
        className={isActive ? "active" : ""}
        onClick={(e) => onExpand(record, e)}
      >
        {isActive ? (
          <UpOutlined style={{ color: "white" }} />
        ) : (
          <DownOutlined style={{ color: "#1A1C1D" }} />
        )}
      </CustomExpandleIconWrapper>
    );
  };

  const CourseTitle = ({ text,addedOn }: { text: any,addedOn:string }) => {
    return (
      <CourseTitleWrapper>
        <img src={Icons.OrangeBook} alt="bookIcon" />
        <div>
          <p className="title">
            {text} <img src={Icons.BlueLink} alt="link" />
          </p>
          <p className="addedOn">Added On: {addedOn && moment(addedOn).format('DD MMM YYYY')}</p>
        </div>
      </CourseTitleWrapper>
    );
  };

  const rowClassName = (record: any) => {
    if (record.children) {
      return "parent-row";
    }
    return "child-row";
  };

  return (
    <AnalyticsWrapper>
      <div className="header">
        <Heading>Detailed Video Analytics</Heading>
        <div className="rightBar">
          <Input.Search className="searchBar" disabled placeholder="Search Videos" />
          <ExportButton disabled>Export CSV</ExportButton>
        </div>
      </div>
      <CustomTable
        pagination={false}
        expandable={{
          expandIcon: ({ expanded, record, onExpand }) => (
            <CustomExpandleIcon
              onExpand={onExpand}
              isActive={expanded}
              record={record}
            />
          ),
        }}
        loading={isLoading}
        rowClassName={rowClassName}
        dataSource={data || []}
        columns={columns}
      />
    </AnalyticsWrapper>
  );
};
const AnalyticsWrapper = styled.div`
  .header {
    ${tw`flex justify-between items-center mt-7 mb-3`}
  }
  .rightBar {
    ${tw`flex gap-x-3`}
  }
  .searchBar {
    ${tw`w-[372px]`}
    button {
      ${tw`bg-white text-red-500 border-l-0 h-[31px]`}
    }
  }
  .searchBar .ant-btn-icon {
    ${tw`text-[#228AC2]`}
  }
`;
const ExportButton = styled(Button)`
  ${tw`bg-[#228AC2] text-white text-[14px] font-semibold`}
`;
const CustomTable = styled(Table)`
  ${tw`shadow-md mb-4`}
  .childSource {
    ${tw`ml-11 `}
  }
  .ant-table-thead > tr > th {
    background-color: #f8f8f8;
    border: 1px solid #e9e9e9;
  }
  tbody tr {
    border: 1px solid #e9e9e9;
  }
  thead tr .ant-table-cell {
    ${tw`font-semibold text-[14px] text-[#0F4D91]`}
  }
  td {
    ${tw`font-semibold text-[#191919] text-[14px] text-center`}
  }
  .child-row {
    ${tw`bg-[#F8F8F8]`}
    border:1px solid #DDDDDD;
  }

  tr td:first-child {
    ${tw`text-left`}
  }
`;
const CustomExpandleIconWrapper = styled.div`
  ${tw`w-[20px] h-[20px] rounded-md float-left flex mt-[2px] cursor-pointer mr-2 border-[#E4E8E8] border-[1px] border-solid flex items-center justify-center`}
  &.active {
    ${tw`bg-[#0F4D91]`}
  }
`;
const CourseTitleWrapper = styled.div`
  ${tw`flex w-fit items-start gap-x-2 inline`};
  .title {
    ${tw`flex gap-2 items-end max-w-[247px] text-[#0D3C70] text-[14px] font-semibold cursor-pointer`}
  }
  img {
    margin-bottom: 4px;
  }
  .addedOn {
    ${tw`text-[#A4A4A4] text-[12px] italic`}
  }
`;

export default AnalyticsTable;
