import axios from "axios";
import config from "../../config/properties";
import api from "..";

export const SendOtp = (data) => {
  return async () => {
    try {
      const getResponse = await axios.post(
        config.auth_url + `/api/v4/expert/send-otp`,
        data
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const ResendOtp = (data) => {
  return async () => {
    try {
      const getResponse = await axios.post(
        config.auth_url + `/api/v4/expert/retry-otp`,
        data
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const VerifyOtp = (data) => {
  return async () => {
    try {
      const getResponse = await axios.post(
        config.auth_url + `/api/v4/expert/verify-otp`,
        data
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const LOGIN_SUCCESS = async (accessToken, refreshToken, expiresIn) => {
  const expiresAt = JSON.stringify(expiresIn * 800 + new Date().getTime());
  await cookiesStorage({
    name: "antwalk_id",
    value: accessToken,
    type: "set",
  });
  if (refreshToken) {
    await cookiesStorage({
      name: "antwalk_id_refresh",
      value: refreshToken,
      type: "set",
    });
  }
  await cookiesStorage({
    name: "expires_at",
    value: expiresAt,
    type: "set",
  });
};

export const getAuthorId = async (payload) => {
  try {
    const resp = await api.get(`/v4/content-partners/author?user-id=${payload.userId}`)
    if(!resp.data.data) return {}
    return (
      {
        function:[
          {
            author_id:resp.data.data.authorId,
            first_name:resp.data.data.firstName,
            is_active:resp.data.data.isActive
          }
        ]
      }
    )
  } catch (error) {
    return error;
  }
};

export const signup = async (data) => {
    try {
      const getResponse = await axios.post(
        config.base_url + `/api/v5/experts/onboarding/request`,
        data
      );
      return getResponse;
    } catch (e) {
      return e;
    }
};
