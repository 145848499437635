import styled from "styled-components";

export const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  height: calc(100vh - 60px);
  position: fixed;
  background-color: #FFF;
  left: 0;
  top: 60px;
  width: ${props => props.width};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 15px;
  transition: all 0.35s;
  z-index: 100;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .tab {
    width: 100%;
    overflow: hidden;
    border-left: 4px solid transparent;
  }

  .tab-label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 14px;
    background-color: #FFF;
    font-weight: regular;
    cursor: pointer;
    color: #4F4F4F;
    /* Icon */

    svg {
      stroke: #666666;
    }
  }
  .link-btn.active,
  .tab-label.active,
  .tab.active,
  .tab:hover {
    border-left-color: #0DB8CC;
  }
  
  .tab.active,
  .link-btn.active,
  .tab-label.active,
  .link-btn.sublink:hover,
  .tab-label.accordian:hover,
  .tab-label:hover {
    background-color: rgba(13, 184, 204, 0.10);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #0DB8CC;
    svg {
      stroke: #0DB8CC;
    }
  }

  .tab-label.accordian {
    color: #111928;
  }

  .tab-label.accordian::after {
    content: "❯";
    text-align: center;
    transition: all 0.35s;
  }

  .tab-content {
    max-height: 0;
    color: #4F4F4F;
    background: white;
    transition: all 0.35s;
  }

  input:checked + .tab-label {
    background: #FFF;
  }
  
  input:checked + .tab-label::after {
    transform: rotate(90deg);
  }

  input:checked ~ .tab-content {
    max-height: 100vh;
  }

  .link-btn.sublink {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px 0px;
    margin-left: 8px;
    font-size: 14px;
    .tab-icon {
      height: 24px;
      width 24px;
      padding: 0px 40px 0px 0px;
    }
    .label {
      width: 100%;
    }
  }
`;
