import { InfoCircleFilled } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import HowItsCalculated from './HowItsCalculated'

type props = {
    button?:boolean;
    setIsModalOpen?:Dispatch<SetStateAction<boolean>>;
    data:any
}

const RevenueDetailCard:React.FC<props> = ({button = false,setIsModalOpen = ()=>{},data}) => {
  
  return (
    <RevenueDetailCardWrapper>
        <p className='label'>{data?.label} {data?.span? <span>{`(${data?.span})`}</span> : ''} {data?.info && <Tooltip color='white' placement='left' overlayStyle={{maxWidth:650}} overlayClassName='custom-tooltip' title={<HowItsCalculated/>}>
            <InfoCircleFilled style={{color:'#0F4D91',cursor:'pointer'}} color='#0F4D91' /></Tooltip>}</p>
        <p className='value'>{data?.value}</p>
        {data?.info && <ViewButton onClick={() => setIsModalOpen(true)}>
        View Details
        </ViewButton>}
    </RevenueDetailCardWrapper>
  )
}

const RevenueDetailCardWrapper = styled.div`
    ${tw`bg-[rgb(249,249,249)] p-4 flex rounded-md flex-col`}
    .label {
        ${tw`font-semibold text-[16px] text-[#3A3A3A]`}
    } 
    .label span {
        ${tw`text-[#969696] italic`}
    }
    .value {
        ${tw`text-[#0F4D91] font-bold text-[24px]`}
    }
   
`

const ViewButton = styled(Button)`
    ${tw`w-fit bg-[#228AC2] text-white mt-1`}
`

export default RevenueDetailCard