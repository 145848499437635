import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Summary from './Summary'
import RevenueShare from './RevenueShare'
import revenueApis from '../../services/revenue'
import WarningDummyData from '../../components/AtomicDesign/Molecule/WarningDummyData'

const RevenueDashboard = () => {
  const[dashboardData,setDashboardData] = useState({
    revenueShare:null,
    summary:null
  })
  const[isLoading,setIsLoading] = useState(true)
  const getData = async() => {
      const resp = await revenueApis.getDashboardData('3');
      if(resp){
        setDashboardData(resp)
      }
      setIsLoading(false)
  }
useEffect(() => {
  getData()
},[])
  return (
    <RevenueWrapper>
         <Heading>
    Summary
  </Heading>
  <div className='warningContainer'>
     <WarningDummyData/>
     </div>
  <Summary dashboardSummaryData={dashboardData?.summary}/>
  <RevenueShare revenueShareData={dashboardData?.revenueShare} isLoading={isLoading}/>
  </RevenueWrapper>
  )
}

const RevenueWrapper = styled.div`
  margin-right:32px;
  margin-top:40px;
  position:relative;
  .warningContainer {
    ${tw`w-full absolute flex justify-center top-[-20px]`}
  }
`

export const Heading = styled.h2`
  ${tw`text-[16px] font-semibold my-3 text-[#111928]`}
`

export default RevenueDashboard;