import React, { useEffect, useState } from "react";
import { Wrapper, Section } from "./style";
import Header from "./Header";
import Sidebar from '../AtomicDesign/Molecule/Sidebar';
import Emitter from "../../lib/utils/emitter";
import { Events } from "../../assets/constants/Events";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  // Adding 32px for extra gap to 56px and 250px sidebar width.
  let location = useLocation();
  const path = location?.pathname;

  const [width, setWidth] = useState("88px");

  const handleExpansion = ({ value }) => {
    if (value) {
      setWidth("282px");
    } else {
      setWidth("88px");
    }
  }

  useEffect(() => {
    Emitter.on(Events.EXPAND_SIDEBAR, handleExpansion);
    () => {
      Emitter.off(Events.EXPAND_SIDEBAR, handleExpansion);
    }
  }, []);

  return (
    <Wrapper>
      <Header {...props} />
      <div style={{
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "60px",
        minHeight: "calc(100vh - 60px)",
      }}>
        <Section
          id="main-section"
          width={width}
        >
          {!path.startsWith('/e') && 
          <Sidebar />}
          {props?.children}
        </Section>
      </div>
    </Wrapper>
  )
};

export default Layout;
