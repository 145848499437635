import { gql } from "@apollo/client";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { message } from "antd";
import { useSelector } from "react-redux";
import DOMPurify from 'dompurify';

export const client = () => {
  const val = localStorage.getItem("token");
  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL + `/v1/graphql`,
    cache: new InMemoryCache(),
    headers: { Authorization: val },
  });
};

export const clientWithoutCache = () => {
  const val = localStorage.getItem("token");
  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL + `/v1/graphql`,
    headers: { Authorization: val },
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });
};

export const clientWithToken = () => {
  const val = localStorage.getItem("token");
  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL + `/v1/graphql`,
    cache: new InMemoryCache(),
    headers: { Authorization: val },
  });
};

// export const showErrorMsg = (record) => {
//   if (record.errors) {
//     record.errors.map((item) => message.error(`${item.param} ${item.msg}`));
//   } else if (record.message) {
//     message.error(record.message);
//   } else {
//     message.info("Somthing went wrong. Please reload page!!"); // if not getting any backend message.
//     // captureException(record);
//   }
// };

export const showErrorMsg = (record) => {
  if (record.error) {
    message.error(record.error);
  } else if (record.errors) {
    record.errors.map(item => (
      // message.error(`${item.param} ${item.msg}`)
      message.error(item.param ? `${item.param} ${item.msg}` : `${item.path} ${item.msg}`)
    ));
  } else if (record.response.data.errors) {
    record.response.data.errors.map(item => (
      message.error(`${item.param} ${item.msg}`)
    ));
  } else if (record.message) {
    message.error(record.message);
  } else {
    message.info('Somthing went wrong. Please reload page!!'); // if not getting any backend message.
    captureException(record);
  }
};

export const clientWithoutToken = () => {
  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URL + `/v1/graphql`,
    cache: new InMemoryCache(),
  });
};

export const HTML_TO_TEXT = (html, id) => (
  <div
    id={id || ''}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
  />
);