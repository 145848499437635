export const theme = (type) => {
  const themeConfig = {
    default: {
      color: {
        ant_primary: "#FAA843",
        ant_primary_80: "#FBBE74",
        ant_primary_60: "#FCD19C",
        ant_primary_40: "#FDE3C4",
        ant_primary_20: "#FEF6EB",

        ant_secondary: "#0F4D91",
        ant_secondary_80: "#1777DE",
        ant_secondary_60: "#59A1EE",
        ant_secondary_40: "#A3CBF5",
        ant_secondary_20: "#EDF5FD",

        bg_primary: "#228AC2",
        bg_primary_80: "#52AEE0",
        bg_primary_60: "#86C7EA",
        bg_primary_40: "#BADFF3",
        bg_primary_20: "#EEF7FC",

        ant_black: "#000000",
        ant_black_90: "#000000E6",
        ant_black_75: "#000000BF",
        ant_black_50: "#00000080",
        ant_black_25: "#00000040",
        ant_black_10: "#0000001A",
        ant_gray: "#C3C6CC",
        ant_gray_90: "#C3C6CCE6",
        ant_gray_75: "#C3C6CCBF",
        ant_gray_50: "#C3C6CC80",
        ant_gray_25: "#C3C6CC40",
        ant_gray_10: "#C3C6CC1A",
        ant_white: "#FFFFFF",
        ant_purple: "#7C1CDC",
        ant_red: "#F22E2E",
        ant_blue: "#2EA0F2",
        ant_cyan: "#07C4ED",
        ant_green: "#0AB21B",

        // White
        WHITE: "#FFFFFF",
      },
    },
    seablue: {
      color: {
        // gray + white combination
        BODY_BASE: "#F8F9FB",
        BODY_PRIMARY: "#F1F3F8",
        BODY_SECONDARY: "#F0F0F0",
        // White
        WHITE: "#FFFFFF",
        // Black
        BLACK: "#000000",
        // Ocean
        OCEAN: "#00799E",
        // Topaz
        TOPAZ: "#0DB8CC",
        // Gray
        GRAY_DISABLED: "#6C6C6C",
        GRAY_BORDER: "#CCCCCC",
        GRAY_BACKGROUND: "#D9D9D9",
        // Primary
        PRIMARY_500: "#0F4D91",
        PRIMARY_400: "#265E9C",
        PRIMARY_300: "#3E70A7",
        PRIMARY_200: "#5782B2",
        PRIMARY_100: "#6F94BD",
        // Secondary
        SECONDARY_500: "#072648",
        SECONDARY_400: "#092E57",
        SECONDARY_300: "#0A3565",
        SECONDARY_200: "#0C3D74",
        SECONDARY_100: "#0D4582",
        // Base
        BASE_500: "#228AC2",
        BASE_400: "#3895C8",
        BASE_300: "#4EA1CE",
        BASE_200: "#64ADD4",
        BASE_100: "#7AB8DA",
        // Dark Base
        DARK_BASE_500: "#114561",
        DARK_BASE_400: "#145274",
        DARK_BASE_300: "#176087",
        DARK_BASE_200: "#1B6E9B",
        DARK_BASE_100: "#1E7CAE",
        // Light
        LIGHT_500: "#FAA843",
        LIGHT_400: "#FAB055",
        LIGHT_300: "#FBB968",
        LIGHT_200: "#FBC27B",
        LIGHT_100: "#FCCA8E",
        // Light Dark
        LIGHT_DARK_500: "#7D5421",
        LIGHT_DARK_400: "#966428",
        LIGHT_DARK_300: "#AF752E",
        LIGHT_DARK_200: "#C88635",
        LIGHT_DARK_100: "#E1973C",
        // Success
        SUCCESS_PRIMARY: "#32E4C8",
        SUCCESS_SECONDARY: "#24A148",
        SUCCESS_SECONDARY_100: "#24A148AA",
        // Warning
        WARNING_PRIMARY: "#EED202",
        WARNING_PRIMARY_100: "#EED202AA",
        WARNING_SECONDARY: "#FFD104",
        // Danger
        DANGER_PRIMARY: "#DA1E28",
        DANGER_PRIMARY_100: "#DA1E28AA",
      },
    },
  };
  if (themeConfig[type]) {
    return themeConfig[type];
  }
  return themeConfig.default;
};
