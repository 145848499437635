import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AwUserIcon from "../Atom/AwUserIcon";
import { Divider } from "antd";
import AwProfileMenuCard from "./AwProfileMenuCard";
import AwProfileMenuList from "./AwProfileMenuList";
import { ThemeColor } from "../../../assets/theme";
import ButtonView from "../../AtomicDesign/Atom/Button/default";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RouteEnum from "../../../assets/constants/RouteEnum";

const AwProfileMenuDropdown = ({
  imageSrc,
  // clearStore,
  // isLoggedIn = false,
  // hasUserDetails = null,
}) => {
  const data = useSelector((state) => state?.homePageData);
  const [image, setImage] = useState("");
  const [profile, setProfile] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();

  const onClickOutside = (e) => {
    let specifiedElement = document.getElementById("menu_dropdwon");
    if (specifiedElement) {
      let isClickInside = specifiedElement.contains(e.target);
      if (isClickInside) {
      } else {
        setShowProfile(false);
      }
    }
  };

  useEffect(() => {
    setImage(data?.list?.photo?.imageurls?.url);
    document.addEventListener("click", onClickOutside);
    return () => document.removeEventListener("click", onClickOutside);
  }, [data]);

  const onLogout = async () => {
    localStorage.clear();
    navigate(RouteEnum.LOGIN);
  };
  return (
    <MenuDropdown id="menu_dropdwon">
      <span className="userIcon">
        <AwUserIcon
          handleClick={() => setShowProfile(!showProfile)}
          type="image"
          imgSrc={image}
        />
        {showProfile && (
          <div className="dropdown_container profile_dropdown">
            <div className="dropdown">
              <AwProfileMenuCard
                name={profile?.name}
                email={profile?.email}
                profilePic={<AwUserIcon type="image" imgSrc={imageSrc} />}
              />
              <Divider />
              <AwProfileMenuList />

              <div className="logout_board">
                <Divider />
                <div className="logout_section">
                  <ButtonView
                    label="Logout"
                    outline
                    width="100px"
                    shape="pill"
                    onClick={() => onLogout()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </span>
    </MenuDropdown>
  );
};

export default AwProfileMenuDropdown;

const MenuDropdown = styled.div`
  position: relative;
  .dropdown_container {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
  }
  .dropdown {
    background: ${ThemeColor().WHITE};
    box-shadow: 1px 1px 15px 0px ${ThemeColor().GRAY_60};
    border-radius: 8px;
    padding: 8px;
    margin-top: 12px;
  }
  .userIcon {
    position: relative;
    cursor: pointer;
    // &:hover .dropdown_container {
    //   display: block;
    //   cursor: select;
    // }
  }

  // .logout_board {
  //    margin-top: 64px;
  // }

  .logout_section {
    padding: 0 6px 6px 6px;
    display: flex;
    justify-content: center;
  }
`;
