export const ThemeColor = () => ({
    PRIMARY_DARK: '#dd9133',
    PRIMARY: '#FAA843',
    PRIMARY_80: '#FBBE74',
    PRIMARY_60: '#FCD19C',
    PRIMARY_40: '#FDE3C4',
    PRIMARY_20: '#FCF0DE',
    PRIMARY_15: '#FFF2E3',

    PRIMARY_10: '#FEF6EB',

    SECONDARY: '#0F4D91',
    SECONDARY_80: '#1777DE',
    SECONDARY_60: '#59A1EE',
    SECONDARY_40: '#A3CBF5',
    SECONDARY_40_v1: '#DBECFF',
    SECONDARY_20: '#EDF5FD',
    SECONDARY_20_v1: '#EDF6FF',

    BASE: '#228AC2',
    BASE_80: '#52AEE0',
    BASE_60: '#86C7EA',
    BASE_40: '#BADFF3',
    BASE_20: '#EEF7FC',

    BLACK: '#000000',
    WHITE: '#FFFFFF',
    GRAY: '#616161',
    GRAY_80: '#828282',
    GRAY_60: '#999999',
    GRAY_40: '#BEBEBE',
    GRAY_20: '#DEDEDE',
    GRAY_BLUE: '#B7CADE',

    GRAY_GRADIENT: 'linear-gradient(180deg, #B5B5B5 0%, #616161 100%)',

    SUCCESS: '#27AE60',
    SUCCESS_20: '#E2F5EA',

    ERROR: '#EC3E3E',
    ERROR_20: '#EC3E3E1A',
  });

export const ThemeSpace = () => ({
    0: '0px',
    4: '4px',
    8: '8px',
    12: '12px',
    16: '16px',
    20: '20px',
    22: '22px',
    24: '24px',
    28: '28px',
    32: '32px',
    36: '36px',
    40: '40px',
    44: '44px',
    48: '48px',
    52: '52px',
    56: '56px',
    60: '60px',
    64: '64px',
    72: '76px',
    84: '84px',
    96: '96px',
    100: '100px',
    120: '120px',
  });

export const ThemeFontSize = () => ({
    12: '12px',
    14: '14px',
    16: '16px',
    18: '18px',
    20: '20px',
    22: '22px',
    24: '24px',
    28: '28px',
    32: '32px',
    38: '38px',
    48: '48px',
    54: '54px',
  });
