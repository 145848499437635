import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { getAuthorId } from "./services/auth";
import { GetUserId } from "./redux/actions/auth";
import { useDispatch } from "react-redux";
import Loader from './components/AtomicDesign/Atom/Loader';
import {
  GetAllHomeData
} from "./services/home";
import { AllHomeResponse } from "./redux/actions/home";
import RouteEnum from "./assets/constants/RouteEnum";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const logout = () => {
    setIsLoading(false);
    localStorage?.clear();
    navigate(RouteEnum.LOGIN);
  }

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const antwalkUserId = localStorage.getItem('antwalk_user_id');
    if (token && antwalkUserId) {
      const authPayload = {
        userId: antwalkUserId,
      };
      try {
        const apiAuth = await getAuthorId(authPayload, token);
        if (apiAuth?.function?.length > 0 && apiAuth?.function[0]?.author_id) {
          dispatch(GetUserId(apiAuth?.function[0]?.author_id));
          const apiResp = await dispatch(GetAllHomeData(apiAuth?.function[0]?.author_id));
          if (apiResp?.status === 200) {
            dispatch(AllHomeResponse(apiResp?.data?.data));
          }
          setIsLoading(false);
          setIsAuthenticated(true);
        } else {
          logout()
        }
      } catch (err) {
        logout();
      }
    } else {
      logout();
    }
  };

  // In case of Protected route fetch user data before showing screen
  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (isAuthenticated && !isLoading) {
    return children || <Outlet />;
  }

  return <Navigate replace to={RouteEnum.LOGIN} />;
};
export default ProtectedRoutes;
