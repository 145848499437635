import api from ".."
import { showErrorMsg } from "../../lib/utils/helpers";

const getAnalyticsData = async (partnerId:string) => {
    try {
        const res = await api.get(`/v4/content-partners/${partnerId}/video-analytics`);
        return res?.data?.data
    } catch (error) {
        showErrorMsg(error);
    }
}

export default {getAnalyticsData}