import { Spin } from 'antd'
import React from 'react'

// Full screen loading component
// Todo: Update it to be customizable
function Loader({ isLoading }) {
    return (
        <div style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Spin spinning={isLoading} />
        </div>
    )
}

export default Loader;
