// Define global colors here
export default {
    PRIMARY: '#01579B',
    PRIMARY_RED_500: '#EF5350',
    PRIMARY_RED: '#BF4848',
    PRIMARY_50: '#F2E7FE',
    PRIMARY_100: '#D7B7FD',
    PRIMARY_200: '#BB86FC',
    PRIMARY_300: '#9E54FC',
    PRIMARY_400: '#7F22FD',
    PRIMARY_600: '#4B01D1',
    PRIMARY_700: '#3700B3',
    PRIMARY_800: '#280096',
    PRIMARY_900: '#EF5350',
  
    SECONDARY_BLUE: '#2D9CDB',
    SECONDARY_50: '#F2E7FE',
    SECONDARY_100: '#4FFEE4',
    SECONDARY_200: '#18FDE1',
    SECONDARY_300: '#03DAC6',
    SECONDARY_400: '#02BEB2',
    SECONDARY_500: '#01A39D',
    SECONDARY_600: '#018786',
    SECONDARY_700: '#00696B',
    SECONDARY_800: '#004B4F',
    SECONDARY_900: '#002F33',
  
    WHITE_HIGH: '#FFFFFF',
    WHITE_MEDIUM: 'rgba(255, 255, 255, 0.7)',
    WHITE_DISABLED: 'rgba(255, 255, 255, 0.5)',
    WHITE_INTACTIVE: 'rgba(255, 255, 255, 0.54)',
  
    BLACK: '#000000',
    BLACK_HIGH: 'rgba(0, 0, 0, 0.87)',
    BLACK_MEDIUM: 'rgba(0, 0, 0, 0.6)',
    BLACK_DISABLED: 'rgba(0, 0, 0, 0.38)',
    BLACK_INACTIVE: 'rgba(0, 0, 0, 0.54)',
    BLACK_FOCUS_OVERLAY: 'rgba(0, 0, 0, 0.12)',
    BLACK_HOVER_OVERLAY: 'rgba(0, 0, 0, 0.08)',
  
    SURFACE_CARD: '#FAFAFA',
    SURFACE_APP: '#FFFFFF',
    SURFACE_MENU: '#FAFAFA',
    SURFACE_SHEETS: '#FAFAFA',
    SURFACE_DRAWERS: '#FFFFFF',
    SUFACE_DIALOGS: '#FFFFFF',
    SURFACE_SIDE: '#FFFFFF',
  
    SECONDARY: '#EC9D93',
  
    BG_GREY: 'rgb(247,247,247,1)',
    TEXT_BLACK: '#1F273D',
  
    LC_COLOR: '#915FD0',
    PROJECT_COLOR: '#00B3FF',
    BOOTCAMP_BACKGROUND: '#e7e9f04d', // #b6b8bf4d
    BOOTCAMP_TEXT_COLOR: '#203859',
    ORANGE_COLOR: '#FAA843',
  };
  