import api from ".."
import { showErrorMsg } from "../../lib/utils/helpers";

const getPayoutForTheMonth = async (partnerId:string) => {
    try {
        const res = await api.get(`v4/content-partners/${partnerId}/revenue-monthly-analytics`);
        return res?.data?.data
    } catch (error) {
        showErrorMsg(error);
    }
}

const getDashboardData = async (partnerId:string) => {
    try {
        const res = await api.get(`v4/content-partners/${partnerId}/revenue-analytics`);
        return res?.data?.data
    } catch (error) {
        showErrorMsg(error);
    }
}

export default {getPayoutForTheMonth,getDashboardData};