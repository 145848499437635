import axios from "axios";
import config from "../../config/properties";
import api from "../apiInterceptors";

import { client, clientWithoutCache } from "../../lib/utils/helpers";
import {
  EXPERT_ALUM_UPDATE,
  EXPERT_ALUM_DELETE,
  EXPERT_COMPANIES_UPDATE,
  EXPERT_COMPANY_DELETE,
  EXPERT_WORK_EXPERIENCE,
} from "../../lib/graphQlApi";
import { message } from "antd";
import {
  ADD_EXTERNAL_LINKS,
  DELETE_EXPERT_FUNCTION,
  DELETE_EXPERT_INDUSTRY,
} from "../../lib/graphOl";

export const GetAllHomeData = (id) => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await api.get(`/api/v5/experts/${id}/home`, {
        headers: {
          Authorization: jwtToken,
        },
      });
      return getResponse;
    } catch (e) {}
  };
};

export const DeleteIndustry = async (payload) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: DELETE_EXPERT_INDUSTRY,
      variables: payload,
    });

    if (response.data.delete_neopayment_author_industry_mapping) {
      message.success("Expert Industry Deleted");
    }
  } catch (error) {
    message.error("Not able to delete Expert Industry");
  }
};

export const DeleteFunctions = async (payload) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: DELETE_EXPERT_FUNCTION,
      variables: payload,
    });

    if (response.delete_single_neopayment_author_functions) {
      message.success("Expert Function Deleted");
    }
  } catch (error) {
    message.error("Not able to delete Expert function");
  }
};

export const AddTopics = (data, id) => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await api.post(
        `/api/v5/experts/${id}/author-credentials?credential-type=topics`,
        data,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const AddAwards = (data, id) => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await api.post(
        `/api/v5/experts/${id}/author-credentials?credential-type=awards`,
        data,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const AddSkills = (data, id) => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await axios.post(
        config.base_url +
          `/api/v5/experts/${id}/author-credentials?credential-type=skills`,
        data,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const GetAllProgramVideos = (type, id) => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await axios.get(
        config.base_url + `/api/v5/experts/${id}/programs?filter-type=${type}`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const GetAllLinks = () => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await axios.get(
        config.base_url + `/api/v5/experts/external-links/list`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const GetAllTrendingVideos = (type, id) => {
  const jwtToken = localStorage.getItem("token");
  return async () => {
    try {
      const getResponse = await axios.get(
        config.base_url + `/api/v5/experts/${id}/videos?filter-type=${type}`,
        {
          headers: {
            Authorization: jwtToken,
          },
        }
      );
      return getResponse;
    } catch (e) {
      return e;
    }
  };
};

export const AddExternalLinks = async (payload) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: ADD_EXTERNAL_LINKS,
      variables: payload,
    });

    if (response?.data?.update_neopayment_author_by_pk) {
      message.success("Expert Links Saved");
    }
    return response;
  } catch (error) {
    message.error("Failed to save links ");
  }
};

export const AddAlums = async (payload) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: EXPERT_ALUM_UPDATE,
      variables: {
        objects: payload,
      },
    });

    if (response.data.insert_neopayment_author_education) {
      message.success("Expert Alum Saved");
    }
  } catch (error) {
    message.error("Not able to save Expert Alum");
  }
};

export const DeleteAlums = async (name) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: EXPERT_ALUM_DELETE,
      variables: {
        name: name, // Add the "name" variable
      },
    });

    if (response.data.delete_neopayment_author_education) {
      message.success("Expert Alum Deleted");
    }
  } catch (error) {
    message.error("Not able to delet Expert Alum");
  }
};

export const AddCompanies = async (payload) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: EXPERT_COMPANIES_UPDATE,
      variables: {
        objects: payload,
      },
    });

    if (response.data.insert_neopayment_author_experience) {
      message.success("Expert Companies Saved");
    }
  } catch (error) {
    message.error("Not able to save Expert Companies");
  }
};

export const DeleteComapanies = async (name) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: EXPERT_COMPANY_DELETE,
      variables: {
        name: name, // Add the "name" variable
      },
    });

    if (response.data.delete_neopayment_author_experience) {
      message.success("Expert Company Deleted");
    }
  } catch (error) {
    message.error("Not able to Delete Expert Company");
  }
};

export const getOGImage = (url) => {
  return async () => {
    try {
      const response = await fetch(url);
      const html = await response.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      const ogImageElement = doc.querySelector('meta[property="og:image"]');
      const ogImage = ogImageElement
        ? ogImageElement.getAttribute("content")
        : null;

      const ogTitleElement = doc.querySelector('meta[property="og:title"]');
      const ogTitle = ogTitleElement
        ? ogTitleElement.getAttribute("content")
        : null;

      return { ogImage, ogTitle };
    } catch (error) {
      message.error("Error retrieving OG image and title");
      return null;
    }
  };
};

export const addWorkExperience = async (payload) => {
  try {
    const response = await clientWithoutCache().mutate({
      mutation: EXPERT_WORK_EXPERIENCE,
      variables: {
        ...payload,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const addTestimonials = async (data, id) => {
  const jwtToken = localStorage.getItem("token");
  try {
    const getResponse = await api.post(
      `/api/v5/experts/${id}/testimonials`,
      data,
      {
        headers: {
          Authorization: jwtToken,
        },
      }
    );
    return getResponse;
  } catch (e) {
    return e;
  }
};

export const getTopics = async (q) => {
  try {
    const jwtToken = localStorage.getItem("token");
    const { title, functionIds } = q;
    const params = new URLSearchParams();

    if (title) params.append("title", title);
    if (functionIds?.length) params.append("function-ids", functionIds);

    const response = await api.get(`/api/v5/experts/topics/list?${params}`, {
      headers: {
        Authorization: jwtToken,
      },
    });

    return response;
  } catch (e) {
    return e;
  }
};
