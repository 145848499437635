const getUserToken = (data) => {
  return (dispatch) => {
    dispatch({ type: "GET_TOKEN", data: { data } });
  };
};

const GetUserId = (data) => {
  return (dispatch) => {
    dispatch({ type: "GET_USER_ID", data: { data } });
  };
};

export { getUserToken, GetUserId };
