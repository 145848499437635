import { InfoCircleOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const WarningDummyData = () => {
  return (
    <WarningDummyDataWrapper>
        <InfoCircleOutlined className='infoIcon' color='#D49A05'/>
        <p>The Stats presented on the dashboard are for indicative purpose only, the actual data will be available soon!</p>
    </WarningDummyDataWrapper>
  )
}

const WarningDummyDataWrapper = styled.div`
    ${tw`bg-[#FFFBF2] flex gap-x-2 w-fit p-2 px-4 rounded-md`}
    p {
        ${tw`text-[16px] text-[#D49A05] font-semibold`}
    }
    .infoIcon {
        ${tw`text-[#D49A05]`}
    }
`

export default WarningDummyData