import React from "react";
import styled from "styled-components";
import SummaryCard from "./SummaryCard";
import Icons from "../../../Global/Icons";

const Summary = ({dashboardSummaryData}:{dashboardSummaryData:any}) => {
  const summaryData = [
    {
      icon:Icons.RupeeIcons ,
      label: (
        <p>
         Overall Marketplace <br /> Earnings
        </p>
      ),
      value: dashboardSummaryData?.overallMarketplaceEarnings,
      meta: {
        value:dashboardSummaryData?.creditedThisMonth,
        label:'credited this month'
      }
    },
    {
      icon:Icons.BlueCourseIcon,
      label: (
        <p>
         Total Watch Time <br /> of the Content
        </p>
      ),
      value: dashboardSummaryData?.totalWatchTimeOfTheContent,
      meta: {
        value:dashboardSummaryData?.minutesAddedThisMonth,
        label:'Minutes added this month'
      }
    },
    {
      icon: Icons.UserIcon,
      label: (
        <p>
         Unique Viewers of <br /> the Content
        </p>
      ),
      value: dashboardSummaryData?.uniqueViewersOfTheContent,
      meta: {
        value:dashboardSummaryData?.enrolledThisMonth,
        label:'Enrolled this month'
      }
    },
  ];

  return (
    <SummaryWrapper>
      {summaryData?.map((summary) => (
        <SummaryCard summary={summary} />
      ))}
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

export default Summary;
