import { gql } from "@apollo/client";

export const GET_AUTHOR_ID = gql`
  query ($userId: Int!) @cached {
    function: neopayment_user_profile(where: { user_id: { _eq: $userId } }) {
      firstname
      author_id
      is_active
      __typename
    }
  }
`;

export const EXPERT_EDUCATION_UPDATE = gql`
  mutation insert_multiple_author_educations(
    $objects: [neopayment_author_education_insert_input!]!
  ) {
    insert_neopayment_author_education(
      objects: $objects
      on_conflict: {
        constraint: idx_author_education
        update_columns: [
          status
          pedigree_id
          pedigree_others
          from_date
          to_date
          description
          specialisation
        ]
      }
    ) {
      affected_rows
      __typename
      returning {
        author_id
        pedigree_id
        pedigree_others
        from_date
        to_date
        description
        specialisation
        status
        __typename
      }
    }
  }
`;

export const GET_ALL_PEDGREE = gql`
  query ($name: String!) @cached {
    function: neopayment_pedigree_master(
      where: { pedigree: { _ilike: $name } }
      order_by: { pedigree: asc_nulls_last }
    ) {
      id
      pedigree
      is_active
      __typename
    }
  }
`;

export const EXPERT_WORKEXPERIENCE_UPDATE = gql`
  mutation insert_multiple_author_experiences(
    $objects: [neopayment_author_experience_insert_input!]!
  ) {
    insert_neopayment_author_experience(
      objects: $objects
      on_conflict: {
        constraint: idx_author_experience
        update_columns: [
          status
          company_others
          from_date
          to_date
          designation
          description
          is_current_company
        ]
      }
    ) {
      affected_rows
      __typename
      returning {
        id
        author_id
        company_id
        company_others
        from_date
        to_date
        designation
        description
        is_current_company
        status
        __typename
      }
    }
  }
`;

export const GET_ALL_COMPANIES = gql`
  query ($name: String!) @cached {
    function: neopayment_company_master(
      where: { name: { _ilike: $name } }
      order_by: { name: asc_nulls_last }
    ) {
      id
      name
      is_active
      __typename
    }
  }
`;

export const GET_ALL_INDUSTRIES = gql`
  query ($name: String!) @cached {
    function: neopayment_industry_master(
      where: {
        name: { _ilike: $name }
        is_active: { _eq: true }
        parent_id: { _is_null: true }
      }
      order_by: { seq_num: asc_nulls_last }
    ) {
      id
      name
      is_active
      seq_num
      __typename
    }
  }
`;

export const GET_ALL_SUB_INDUSTRIES = gql`
  query ($name: String!, $id: Int!) @cached {
    function: neopayment_industry_master(
      where: {
        name: { _ilike: $name }
        is_active: { _eq: true }
        parent_id: { _eq: $id }
      }
      order_by: { seq_num: asc_nulls_last }
    ) {
      id
      name
      is_active
      seq_num
      __typename
    }
  }
`;

export const EXPERT_INDUSTRY_UPDATE = gql`
  mutation insert_multiple_neopayment_author_industry_mapping(
    $objects: [neopayment_author_industry_mapping_insert_input!]!
  ) {
    insert_neopayment_author_industry_mapping(
      objects: $objects
      on_conflict: {
        constraint: pk_author_industry_mapping
        update_columns: [is_primary]
      }
    ) {
      returning {
        author_id
        industry_id
        sub_industry_id
        is_primary
        __typename
      }
      __typename
    }
  }
`;

export const DELETE_EXPERT_INDUSTRY = gql`
  mutation delete_single_neopayment_author_industry(
    $industryId: Int!
    $subIndustryId: Int!
    $authorId: Int!
  ) {
    delete_neopayment_author_industry_mapping(
      where: {
        author_id: { _eq: $authorId }
        industry_id: { _eq: $industryId }
        sub_industry_id: { _eq: $subIndustryId }
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_ALL_FUNCTIONS = gql`
  query ($name: String!) @cached {
    function: neopayment_function_master(
      where: {
        name: { _ilike: $name }
        is_active: { _eq: true }
        parent_id: { _is_null: true }
      }
      order_by: { seq_num: asc_nulls_last }
    ) {
      id
      name
      is_active
      seq_num
      __typename
    }
  }
`;

export const GET_ALL_SUB_FUNCTIONS = gql`
  query ($name: String!, $id: Int!) @cached {
    function: neopayment_function_master(
      where: {
        name: { _ilike: $name }
        is_active: { _eq: true }
        parent_id: { _eq: $id }
      }
      order_by: { seq_num: asc_nulls_last }
    ) {
      id
      name
      is_active
      seq_num
      __typename
    }
  }
`;

export const EXPERT_FUNCTIONS_UPDATE = gql`
  mutation insert_multiple_neopayment_author_function_mapping(
    $objects: [neopayment_author_function_mapping_insert_input!]!
  ) {
    insert_neopayment_author_function_mapping(
      objects: $objects
      on_conflict: {
        constraint: pk_author_function_mapping
        update_columns: [is_primary]
      }
    ) {
      returning {
        author_id
        function_id
        sub_function_id
        is_primary
        __typename
      }
      __typename
    }
  }
`;

export const DELETE_EXPERT_FUNCTION = gql`
  mutation delete_single_neopayment_author_functions(
    $functionId: Int!
    $subFunctionId: Int!
    $authorId: Int!
  ) {
    delete_neopayment_author_function_mapping(
      where: {
        author_id: { _eq: $authorId }
        function_id: { _eq: $functionId }
        sub_function_id: { _eq: $subFunctionId }
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_EXTERNAL_LINKS = gql`
  mutation ($links: jsonb!, $id: Int!) {
    update_neopayment_author_by_pk(
      pk_columns: { author_id: $id }
      _set: { external_links: $links }
    ) {
      author_id
      external_links
    }
  }
`;

export const GET_ALL_TOPICS = gql`
query ($name: String) @cached {
    function: neopayment_topic_master(
      where: { is_active: { _eq: true } }
      order_by: { name: asc_nulls_last }
    ) {
      topic_id
      name
      is_active
      __typename
    }
  }
`;

export const GET_ALL_ROLES = gql`query ($functionId: Int!, $name: String!) @cached {
  function_idustry_roles: neopayment_function_to_industry_roles_mapping(where: {function_id: {_eq: $functionId}, industry_role: {name: {_ilike: $name}}, is_active: {_eq: true}}, distinct_on: industry_role_id) {
    industry_role {
      id
      name
      is_active
    }
    function_id
  }
}`;

export const GET_ALL_ACADEMY = gql`query ($name: String!) @cached {
  function: neopayment_function_master(
    where: {name: {_ilike: $name} , is_active:{_eq:true}, academy_slug:{_is_null:false}}
    order_by: {name: asc_nulls_last}
  ) {
    id
    name
    is_active
    academy_slug
    __typename
  }
}`;
