import tw from "twin.macro";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #F7F8FA;
`;

export const Section = styled.div`
  width: calc(100vw - ${(props) => props?.width});
  margin-left: ${props => props?.width};
  background-color: #F7F8FA;
  transition: all 0.35s;
`;
