const RouteEnum = {
  // Auth Routes
  LOGIN: "/login",
  SIGN_UP: '/sign-up',
  LOGIN_OTP: "/login-otp",

  // Home
  HOME: "/kp",

  //Edit profile
  EDIT_PROFILE: "/edit-profile",

  // K-Commerce-Suite Routes
  CREATE_AND_LAUNCH: "/kp/k-commerce-suite/create-launch",
  CREATE_PROGRAM: "/k-commerce-suite/create-program",
  CREATE_ASSESS_TALENT: "/k-commerce-suite/create-assess-talent",
  CREATE_CONSULTATION_SERVICE: "/k-commerce-suite/create-consultation-service",
  CREATE_COACHING_SERVICE: "/k-commerce-suite/create-coaching-service",
  PROMOTION: "/k-commerce-suite/promotion",
  MANAGE_OPERATIONS: "/kp/k-commerce-suite/manage-operations",
  PAYMENT_AND_INVOICE: "/k-commerce-suite/earnings-with-antwalk",

  // Thought Academy Suite Routes
  YOUR_TRAILS: "/kp/thought-academy-suite/your-trails",
  PAY_IT_FORWARD: "/kp/thought-academy-suite/pay-it-forward",
  TRENDING_ON_TRAILS: "/kp/thought-academy-suite/trending-on-trails",

  // Public Trending Trails page
  PUBLIC_TRENDING_ON_TRAILS: '/trending-on-trails',
  // Public view of Author
  PUBLIC_AUTHOR_PROFILE_VIEW: "/author/:id/:name",
  COURSE:'/manage-course',
  COURSE_CONFIG:'/manage-course/config',
  COURSE_UPLOAD:'/manage-course/upload',
  ANALYTICS:'/content-analytics',
  REVENUE:'/revenue-dashboard'
  
};

export default RouteEnum;
